import * as Yup from 'yup';
import { useState, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
    Link,
    Stack,
    Checkbox,
    TextField,
    IconButton,
    InputAdornment,
    FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AuthService from '../../../../services/auth.service';
import Typography from '@mui/material/Typography';
import ReCAPTCHA from "react-google-recaptcha";
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import MeetingService from '../../../../services/meeting.service';
import swal from 'sweetalert';
import * as XLSX from 'xlsx';
import { PopupData } from '../PopupData';

// ----------------------------------------------------------------------
function getParameterCaseInsensitive(object, key) {
    try {
        return object[Object.keys(object)
            .find(k => k.toLowerCase() === key.toLowerCase())
        ];
    }
    catch {
        return null;
    }
}

function IsValidMobile(mobile) {
    var val = mobile + ''
    if (/^\d{10}$/.test(val)) {
        return true;
    } else {
        return false
    }
}

function getValidContactsAndEmails(data) {
    var finalData = { validEmails: [], validMobile: [], invalidEmails: [], invalidMobile: [], validSet: [] }
    data.map((x) => {
        var set = {};
        var mob = getParameterCaseInsensitive(x, 'Mobile');
        var email = getParameterCaseInsensitive(x, 'Email');
        if (typeof mob !== 'undefined' && (mob + '').trim() !== '')
            if (mob && IsValidMobile(mob)) {
                if (finalData.validMobile.find((x) => { return x === mob }) || finalData.invalidMobile.find((x) => { return x === mob })) {
                    throw new Error('Duplicate Mobiles found - ' + mob);
                }
                else {
                    set.Mobile = mob;
                    finalData.validMobile.push(mob);
                }
            }
            else {
                if (finalData.validMobile.find((x) => { return x === mob }) || finalData.invalidMobile.find((x) => { return x === mob })) {
                    throw new Error('Duplicate Mobiles found - ' + mob);
                }
                else
                    finalData.invalidMobile.push(mob);
            }
        if (typeof email !== 'undefined' && (email + '').trim() !== '')
            if (email && IsValidEmail(email)) {
                if (finalData.validEmails.find((x) => { return x === email }) || finalData.invalidEmails.find((x) => { return x === email })) {
                    throw new Error('Duplicate Emails found - ' + email);
                }
                else {
                    set.Email = email;
                    finalData.validEmails.push(email);
                }
            }
            else {
                if (finalData.validEmails.find((x) => { return x === email }) || finalData.invalidEmails.find((x) => { return x === email })) {
                    throw new Error('Duplicate Emails found - ' + email);
                }
                else
                    finalData.invalidEmails.push(email);
            }
        if (Object.keys(set).length !== 0) {
            set.Name = getParameterCaseInsensitive(x, 'Name');
            finalData.validSet.push(set);
        }
    });
    return finalData;
}

function IsValidEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export default function ForgotPasswordForm({ data: ContactsList }) {
    const recaptchaRef = useRef(null);
    const navigate = useNavigate();
    // const [showPassword, setShowPassword] = useState(false);
    const [loginError, setLoginError] = useState("");
    const [isProcessing, setIsProcessing] = useState(false);
    const [contacts, setContacts] = useState({ validEmails: [], validMobile: [], invalidEmails: [], invalidMobile: [], validSet: [] });
    const [popupData, setPopupData] = useState(null);

    // const [isEmailSent, setIsEmailSent] = useState('');

    const LoginSchema = Yup.object().shape({
        // name: Yup.string().required('Name is Required'),
        // email: Yup.string().email('Email must be a valid email address'),
        // mobile: Yup.string()
    });

    const formik = useFormik({
        initialValues: {
            // email: '',
            // mobile: '',
            // name: ''
        },
        validationSchema: LoginSchema,
        onSubmit: async (data1) => {
            setIsProcessing(true);

            var checkDuplicateObj = {};
            var user = AuthService.getCurrentUser()?._doc;

            if (!user) {
                window.location.href = '/dashboard';
                window.location.reload();
            }
            var contactSet = contacts.validSet;
            var finalArray = [];

            for (var i = 0; i < contactSet.length; i++) {
                checkDuplicateObj = {};
                var data = contactSet[i];

                if (data.Mobile != '') {
                    checkDuplicateObj.mobile = String(data.Mobile);
                }
                if (data.Email != '') {
                    checkDuplicateObj.email = data.Email.toLowerCase().trim();
                }

                for (var y = 0; y < ContactsList.length; y++) {
                    if (typeof checkDuplicateObj.mobile !== 'undefined' && ContactsList[y].mobile === checkDuplicateObj.mobile) {
                        setIsProcessing(false);
                        return setLoginError(`Mobile No ${ContactsList[y].mobile} is already given to another participant`);
                    }
                    if (typeof checkDuplicateObj.email !== 'undefined' && ContactsList[y].email === checkDuplicateObj.email) {
                        setIsProcessing(false);
                        return setLoginError(`Email ${ContactsList[y].email} is already given to another participant`);
                    }
                }
                checkDuplicateObj.hostId = user._id;
                checkDuplicateObj.name = data.Name;
                finalArray.push(checkDuplicateObj);
            }

            // if (typeof defaultValues.name !== 'undefined') {
            //     MeetingService.editContact(defaultValues._id, checkDuplicateObj).then((x) => {
            //         swal({
            //             title: 'Participant Edited Successfully',
            //             icon: 'success'
            //         }).then(() => {
            //             window.location.reload();
            //         });
            //     }).catch((err) => {
            //         swal({
            //             title: 'Some Error Occurred',
            //             icon: 'error'
            //         }).then(() => {
            //             window.location.reload();
            //         })
            //     });
            // }
            // else {
            MeetingService.addContacts(finalArray).then((x) => {
                swal({
                    title: 'Participants Saved Successfully',
                    icon: 'success'
                }).then(() => {
                    window.location.reload();
                });
            }).catch((err) => {
                swal({
                    title: 'Some Error Occurred',
                    icon: 'error'
                }).then(() => {
                    window.location.reload();
                })
            });
            // }
            // const captchaToken = await recaptchaRef.current.getValue();
            // recaptchaRef.current.reset();

            // if (captchaToken === '') {
            // setSubmitting(false);
            // return setLoginError('Complete the CAPTCHA');
            // }

            // AuthService.initiatechangePassword(data.email, captchaToken);
            // setIsEmailSent(data.email);
            // setSubmitting(false);
        }
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;


    const readExcel = (file) => {
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;

                const wb = XLSX.read(bufferArray, { type: "buffer" });

                const wsname = wb.SheetNames[0];

                const ws = wb.Sheets[wsname];

                const data = XLSX.utils.sheet_to_json(ws);

                resolve(data);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });

        promise.then((d) => {
            if (d[0] && getParameterCaseInsensitive(d[0], 'Name') && (getParameterCaseInsensitive(d[0], 'Email') || getParameterCaseInsensitive(d[0], 'Mobile'))) {
                try {
                    var contacts = getValidContactsAndEmails(d);
                    setContacts(contacts);
                    if (contacts.validEmails.length === 0 && contacts.validMobile.length === 0) {
                        swal('No Valid Contacts', '', 'error');
                    }
                    else {
                        swal('Contacts imported Successfully', '', 'success');
                    }
                }
                catch (err) {
                    swal(err.message, '', 'error');
                }
            }
            else {
                swal('', 'Excel column with the names "Mobile or Email" and "Name" are mandatory', 'error');
            }
            document.getElementById('fileInput').value = "";
        });
    };

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Box
                    sx={{
                        '& > :not(style)': { m: 1 },
                        display: 'grid'
                    }}
                >
                    <Button
                        variant="outlined"
                        component="label"
                        style={{ width: '65%', marginLeft: 'auto', marginRight: 'auto' }}
                    >
                        Upload Contacts from Excel
                        <input
                            id="fileInput"
                            onChange={(e) => {
                                const file = e.target.files[0];
                                readExcel(file);
                            }}
                            type="file"
                            hidden
                        />
                    </Button>
                    {contacts.invalidEmails.length + contacts.validEmails.length > 0 && (<div style={{ marginTop: '10px' }}><Button onClick={() => { setPopupData({ title: 'Valid Emails', data: contacts.validEmails }) }} variant="text">{'Valid Emails: ' + contacts.validEmails.length}</Button><Button onClick={() => { setPopupData({ title: 'Invalid Emails', data: contacts.invalidEmails }) }} variant="text">{'Invalid Emails: ' + contacts.invalidEmails.length}</Button><br /></div>)}
                    {contacts.invalidMobile.length + contacts.validMobile.length > 0 && (<div style={{ marginTop: '0px' }}><Button onClick={() => { setPopupData({ title: 'Valid Mobiles', data: contacts.validMobile }) }} variant="text">{'Valid Mobiles: ' + contacts.validMobile.length}</Button><Button onClick={() => { setPopupData({ title: 'Invalid Mobiles', data: contacts.invalidMobile }) }} variant="text">{'Invalid Mobiles: ' + contacts.invalidMobile.length}</Button></div>)}
                    {!isProcessing && loginError && (
                        <div style={{ color: "red" }}>
                            <span>{loginError}</span>
                        </div>
                    )}
                    {(contacts.validEmails.length > 0 || contacts.validMobile.length > 0) && (<LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isProcessing}
                    >
                        {'IMPORT'}
                    </LoadingButton>)}
                </Box>
            </Form>
            {popupData && (<PopupData title={popupData.title} data={popupData.data} cancelClick={setPopupData}></PopupData>)}
        </FormikProvider>
    );
}
