import React from "react";
import { CircularProgress, Box } from '@mui/material';
import { Chart, registerables } from 'chart.js';
import AuthService from '../../../../services/auth.service';
import DraggableModal from './DraggableModal';

Chart.register(...registerables);

class ViewGraph extends React.Component {
    constructor(props) {
        super(props);
        this.state = { loading: true, setModal: false }
    }
    render() {
        return (
            <>
                {this.state.loading === true && (
                    <Box>
                        <CircularProgress style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100vh',
                            background: 'white',
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }} />
                    </Box>
                )
                }
                {this.state.setModal === true && <DraggableModal contacts={this.labels} modal={this.state.setModal} setModal={(state) => { this.setState({ setModal: state }) }} />}
                <canvas onClick={(e) => {
                    if (e.detail === 2) {
                        this.setState({ setModal: true })
                    }
                }} id="Chart1"></canvas>
                {/* <ParticipantTable /> */}
            </>
        )
    }
    getTimeInMeeting(joinTimes, leaveTimes) {
        if (!joinTimes || !leaveTimes) {
            return 0;
        }
        var time = 0;
        var index = 0;

        joinTimes.map((x) => {
            time += Math.round(((new Date(leaveTimes[index]) - new Date(x)) / 1000) / 60);
            index++;
        });

        return time;
    }
    componentDidMount() {
        const user = AuthService.getCurrentUser()?._doc;
        if (!user) {
            window.location.href = "/login";
            window.location.reload();
        }
        else {
            const { hostStats, contacts } = this.props;

            var labels = [user.firstName + ' ' + user.lastName];
            var data = [this.getTimeInMeeting(hostStats.joinTimes, hostStats.leaveTimes)];
            var dataLabels = [{ Name: labels[0], data: hostStats }];

            contacts.map((x) => {
                labels.push(x.Name);
                dataLabels.push({ Name: x.Name, data: { joinTimes: x.joinTimes, leaveTimes: x.leaveTimes } });
                data.push(this.getTimeInMeeting(x.joinTimes, x.leaveTimes));
            });

            this.labels = dataLabels;

            this.setState({ loading: false });
            const ctx = document.getElementById('Chart1').getContext('2d');
            const myChart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: labels,
                    datasets: [{
                        label: 'Time spent in minutes',
                        data: data,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                    }]
                },
                options: {
                    indexAxis: 'y',
                    // Elements options apply to all of the options unless overridden in a dataset
                    // In this case, we are setting the border of each horizontal bar to be 2px wide
                    elements: {
                        bar: {
                            borderWidth: 2,
                        }
                    },
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'right',
                        },
                        title: {
                            display: true,
                            text: 'Participant vs Time in meeting'
                        }
                    },
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                }
            });
        }
    }
}

export default ViewGraph;