import React from "react";
import { filter } from "lodash";
import { Icon } from "@iconify/react";
import { sentenceCase } from "change-case";
import { useState, useEffect } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TextField,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TableHead,
  TablePagination,
  ButtonGroup,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
// components
import Page from "../components/Page";
import Label from "../components/Label";
import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "../components/SearchNotFound";
import ContactsButton from "../components/ContactsButton";
import { ContactsToolbar } from "../components/_dashboard/contacts";
import { UserListHead } from "../components/_dashboard/user";

import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

//
//import MeetingList from '../_mocks_/user';
import AuthService from "../services/auth.service";
import { CircularProgress, Box } from "@mui/material";
import MeetingService from "../services/meeting.service";
import swal from "sweetalert";
import { swal as SwalFromReact } from "@sweetalert/with-react";
import NewParticipantSwal from "../components/NewParticipantSwal";
import ImportFromExcelModal from "../components/authentication/video/components/ImportFromExcelModal.js";
import axios from "axios";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { CheckBox } from "@mui/icons-material";
import FormControlLabel from "@mui/material/FormControlLabel";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "title", label: "Name", alignRight: false },
  { id: "date", label: "Email", alignRight: false },
  { id: "time", label: "Mobile", alignRight: false },
  { id: "edit", label: "Edit" },
  { id: "delete", label: "Delete" },
];

function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

var overalldata = [];

export default function Meetings() {
  var user = AuthService.getCurrentUser();
  if (!user) {
    window.location.href = "/hlogin";
  }

  const [isActivated, setIsActivated] = useState(false);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [selectedFromDate, handleFromDateChange] = useState(
    dayjs().startOf("day")
  );
  const [selectedToDate, handleToDateChange] = useState(dayjs().endOf("day"));
  const [deviceType, renameDeviceType] = React.useState(-1);
  const [category, setCategory] = React.useState("");
  const [isRegular, setIsRegular] = React.useState("Regular");
  const [checked, setChecked] = React.useState(true);

  // if (!loaded) {
  //     var User = AuthService.getCurrentUser();
  //     if (!User) {
  //         window.location.href = '/hlogin';
  //     }

  //     AuthService.getUserLogs(User.orgID).then((data) => {

  //     });
  //     // MeetingService.getUserContacts(User._doc._id).then((data) => {
  //     //     setMeetingList(JSON.parse(data.request.response).data);
  //     //     setLoaded(true);
  //     // }).catch((err) => {
  //     //     swal('Error', err.message, 'error').then((x) => {
  //     //         window.location.reload();
  //     //     });
  //     // });

  //     return (
  //         <Box>
  //             <CircularProgress style={{
  //                 display: 'flex',
  //                 justifyContent: 'center',
  //                 alignItems: 'center',
  //                 height: '100vh',
  //                 background: 'white',
  //                 marginLeft: 'auto',
  //                 marginRight: 'auto'
  //             }} />
  //         </Box>
  //     );
  // }

  const handleCheckChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    if (isActivated) {
      fetchData();
    }
  }, [currentPage, rowsPerPage, isRegular]);

  const fetchData = () => {
    if (!isActivated) {
      setIsActivated(true);
    }

    if (isRegular == "Regular") {
      if (category && deviceType != -1) {
        // alert(category);
        console.log(
          `https://vvisionapi.visualbench.com/getStudentLogs?d=${new Date().toLocaleString()}&page=${
            currentPage + 1
          }&perPage=${rowsPerPage}&branchId=${
            user.branchID
          }&category=${category}&devicetype=${
            deviceType == 0 ? "IN" : "OUT"
          }&fromDate=${encodeURIComponent(
            selectedFromDate.toLocaleString()
          )}&unique=${checked}&toDate=${encodeURIComponent(
            selectedToDate.toLocaleString()
          )}`
        );

        axios
          .get(
            `https://vvisionapi.visualbench.com/getStudentLogs?d=${new Date().toLocaleString()}&page=${
              currentPage + 1
            }&perPage=${rowsPerPage}&branchId=${
              user.branchID
            }&category=${category}&devicetype=${
              deviceType == 0 ? "IN" : "OUT"
            }&fromDate=${encodeURIComponent(
              selectedFromDate.toLocaleString()
            )}&unique=${checked}&toDate=${encodeURIComponent(
              selectedToDate.toLocaleString()
            )}`
          )
          .then((response) => {
            var inputData = response.data.logs;
            const transformedData = {};

            // Iterate over each object in the input data
            for (let i = 0; i < inputData.length; i++) {
              const { stuid, studentName, date, device, type } = inputData[i];

              // Check if the stuid already exists in the transformed data object
              if (transformedData[stuid]) {
                // Append the date to the existing data for that stuid
                transformedData[stuid].date += `,${date}`;
              } else {
                // Create a new entry for the stuid in the transformed data object
                transformedData[stuid] = {
                  stuid,
                  studentName,
                  type,
                  device,
                  date,
                };
              }
            }

            // Convert the transformed data object to an array of objects
            const transformedArray = Object.values(transformedData);

            overalldata = transformedArray;

            // setData(transformedArray);
            setTotalCount(transformedArray.length);
            SortData();
            // setTotalCount(response.data.totalCount);
          });
      } else {
        alert("Please fill in all fields");
      }
    } else {
      if (deviceType != -1) {
        axios
          .get(
            `https://vvisionapi.visualbench.com/getUnknownLogs?d=${new Date().toLocaleString()}&page=${
              currentPage + 1
            }&perPage=${rowsPerPage}&branchId=${user.branchID}&devicetype=${
              deviceType == 0 ? "IN" : "OUT"
            }&fromDate=${encodeURIComponent(
              selectedFromDate.toLocaleString()
            )}&toDate=${encodeURIComponent(selectedToDate.toLocaleString())}`
          )
          .then((response) => {
            setData(response.data.logs);
            setTotalCount(response.data.totalCount);
          });
      } else {
        alert("Please fill in all fields");
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setCurrentPage(0);
  };

  const handleChange = (event) => {
    renameDeviceType(event.target.value);
  };

  const handleChangeCate = (event) => {
    setCategory(event.target.value);
  };

  const ShowClick = (event) => {
    if (currentPage != 0) {
      setCurrentPage(0);
    } else {
      fetchData();
    }
  };

  const SortData = () => {
    // Calculate the start and end index of the data range
    const startIndex = currentPage * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    // Get the data falling within the range
    setData(overalldata.slice(startIndex, endIndex));
  };

  return (
    <Page title="Meeting Participants | Vmeet">
      <Container>
        <FormControl style={{ marginLeft: "85%", marginBottom: "15px" }}>
          <InputLabel id="demo-simple-select-label2">View</InputLabel>
          <Select
            labelId="demo-simple-select-label2"
            id="demo-simple-select2"
            value={isRegular}
            onChange={(event) => {
              setIsRegular(event.target.value);
            }}
            style={{ width: "150px" }}
          >
            <MenuItem value={"Regular"}>Regular</MenuItem>
            <MenuItem value={"Unknown"}>Unknown</MenuItem>
          </Select>
          {/* {isRegular == "Regular" && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleCheckChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Unique"
            />
          )} */}
        </FormControl>
        <Card>
          {/* <ContactsToolbar
                        numSelected={selected.length}
                        filterName={filterName}
                        onFilterName={handleFilterByName}
                    /> */}

          <Scrollbar>
            <br />
            {/* <TextField
                            label="Search by name"
                            variant="outlined"
                            value={search}
                                style={{marginLeft: "10px"}}
                            onChange={handleSearchChange}
                        /> */}

            {isRegular == "Regular" && (
              <FormControl>
                <InputLabel id="demo-simple-select-label1">Category</InputLabel>
                <Select
                  labelId="demo-simple-select-label1"
                  id="demo-simple-select1"
                  value={category}
                  onChange={handleChangeCate}
                  style={{ width: "250px", marginLeft: "10px" }}
                >
                  <MenuItem value={"Student"}>Student</MenuItem>
                  <MenuItem value={"Teaching"}>Teaching</MenuItem>
                  <MenuItem value={"NonTeaching"}>Non Teaching</MenuItem>
                </Select>
              </FormControl>
            )}
            <FormControl>
              <InputLabel id="demo-simple-select-label">Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={deviceType}
                onChange={handleChange}
                style={{ width: "80px", marginLeft: "10px" }}
              >
                <MenuItem value={0}>IN</MenuItem>
                <MenuItem value={1}>OUT</MenuItem>
              </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label="From Date"
                format="DD/MM/YYYY hh:mm A"
                sx={{ marginLeft: "10px" }}
                value={selectedFromDate}
                onChange={(newValue) => handleFromDateChange(newValue)}
              />
              <DateTimePicker
                format="DD/MM/YYYY hh:mm A"
                label="To Date"
                sx={{ marginLeft: "10px" }}
                value={selectedToDate}
                onChange={(newValue) => handleToDateChange(newValue)}
              />
            </LocalizationProvider>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: "10px" }}
              startIcon={<SearchIcon />}
              onClick={ShowClick}
            >
              Show
            </Button>

            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {isRegular == "Regular" ? (
                      <>
                        <TableCell>Name</TableCell>
                        <TableCell>Log Time</TableCell>
                        <TableCell>Device</TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell>Log Time</TableCell>
                        <TableCell>Device</TableCell>
                        <TableCell>View</TableCell>
                      </>
                    )}
                    {/* <TableCell>Device Type</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row, index) => {
                    let { stuid, studentName, date, device, type } = row;

                    if (isRegular == "Unknown") {
                      date = new Date(date);

                      date.setHours(date.getHours() + 5);
                      date.setMinutes(date.getMinutes() + 30);

                      date = date.toISOString();
                    }

                    if (stuid == 10204) {
                      console.log("a");
                    }

                    if (date.endsWith(".000Z")) {
                      date = date.replaceAll(".000Z", "");
                      // date = date.slice(0, -5);
                    }

                    const options = {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour12: true,
                      hour: "2-digit",
                      minute: "numeric",
                    };
                    date = date.split(",");
                    var finaldate = [];

                    for (var i = 0; i < date.length; i++) {
                      // alert(date);
                      finaldate.push(
                        new Date(date[i])
                          .toLocaleString(undefined, options)
                          .replace("at ", "")
                      );
                    }

                    // finaldate.join(",");

                    if (isRegular != "Regular") {
                      return (
                        <TableRow hover key={index} tabIndex={-1}>
                          <TableCell align="left">
                            {finaldate.map((line, index) => (
                              <div key={index}>{line}</div>
                            ))}
                          </TableCell>
                          <TableCell align="left">{device}</TableCell>
                          <TableCell align="left">
                            <Button
                              onClick={() => {
                                // alert(row.id);
                                window.open(
                                  "https://vvisionapi.visualbench.com/uploads/2/unknown/" +
                                    row.id +
                                    ".jpg",
                                  "_blank"
                                );
                              }}
                            >
                              ...
                            </Button>
                          </TableCell>
                          {/* <TableCell align="left">{type}</TableCell> */}

                          {/* <TableCell align="right">
                                                        <UserMoreMenu />
                                                    </TableCell> */}
                        </TableRow>
                      );
                    }
                    return (
                      <TableRow hover key={index} tabIndex={-1}>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography style={{marginLeft: '20px'}} variant="subtitle2" noWrap>
                              {studentName}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">
                          {finaldate.map((line, index) => (
                            <div key={index}>{line}</div>
                          ))}
                        </TableCell>
                        <TableCell align="left">{device}</TableCell>
                        {/* <TableCell align="left">{type}</TableCell> */}

                        {/* <TableCell align="right">
                                                        <UserMoreMenu />
                                                    </TableCell> */}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        {/* {activateSwal && <NewParticipantSwal data={MeetingList} defaultValues={DefaultValues} title={typeof DefaultValues.name === 'undefined' ? 'Add Participant' : 'Edit Participant'} onClose={() => { DefaultValues = {}; setActivateSwal(false); }} />}
                {activateImport && <ImportFromExcelModal data={MeetingList} title={'Import Participants from Excel'} onClose={() => { setActivateImport(false); }} />} */}
      </Container>
    </Page>
  );
}
