import * as Yup from 'yup';
import { useState, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
    Link,
    Stack,
    Checkbox,
    TextField,
    IconButton,
    InputAdornment,
    FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AuthService from '../../../services/auth.service';
import Typography from '@mui/material/Typography';
import ReCAPTCHA from "react-google-recaptcha";

// ----------------------------------------------------------------------

export function ForgotPasswordForm({ isEmailSent, setIsEmailSent }) {
    const recaptchaRef = useRef(null);
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [loginError, setLoginError] = useState("");
    // const [isEmailSent, setIsEmailSent] = useState('');

    const LoginSchema = Yup.object().shape({
        email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: LoginSchema,
        onSubmit: async (data, { setSubmitting }) => {
            const captchaToken = await recaptchaRef.current.getValue();
            recaptchaRef.current.reset();

            if (captchaToken === '') {
                setSubmitting(false);
                return setLoginError('Complete the CAPTCHA');
            }

            AuthService.initiatechangePassword(data.email, captchaToken);
            setIsEmailSent(data.email);
            setSubmitting(false);
        }
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    const handleShowPassword = () => {
        setShowPassword((show) => !show);
    };

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                {isEmailSent !== '' ?
                    <Stack spacing={3}>
                        <Typography>
                            {'If the email is registered with us, you will get an email. The link is only valid for 15 minutes'}
                        </Typography>
                    </Stack>
                    :
                    <>
                        <Stack spacing={3}>
                            <TextField
                                fullWidth
                                autoComplete="username"
                                type="email"
                                label="Email address"
                                {...getFieldProps('email')}
                                error={Boolean(touched.email && errors.email)}
                                helperText={touched.email && errors.email}
                            />
                        </Stack>

                        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>

                        </Stack>

                        {!isSubmitting && loginError && (
                            <div style={{ color: "red" }}>
                                <span>{loginError}</span>
                            </div>
                        )}

                        <div style={{ paddingBottom: '10px' }}>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey={'6LewRVAdAAAAAD94PmgWKoCGg79pwzoWwhq86Z-9'}
                            />
                        </div>

                        {isEmailSent === '' &&
                            <LoadingButton
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                loading={isSubmitting}
                            >
                                Change Password
                            </LoadingButton>}
                    </>
                }


            </Form>
        </FormikProvider>
    );
}
