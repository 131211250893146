import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { IconButton, Avatar } from "@mui/material";
import axios from 'axios';
import authService from "../../../services/auth.service";
import { CircularProgress, Box } from '@mui/material';
import swal from 'sweetalert';

const useStyles = makeStyles((theme) => ({
    root: {
        alignSelf: "center",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        "& > *": {
            margin: theme.spacing(1)
        }
    },
    input: {
        display: "none"
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7)
    }
}));

export default function ProfilePicture({ setFile }) {
    const user = authService.getCurrentUser();
    if (!user) {
        window.location.href = "/login";
        window.location.reload();
    }

    const classes = useStyles();
    const [loaded, setLoaded] = useState(false);
    const [url, setUrl] = useState('/static/mock-images/avatars/avatar_default.jpg')

    if (!loaded) {
        axios.get(`/static/profiles/${user._doc._id}`, {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            }
        }).then((x) => {
            if (typeof x.data === 'string' && x.data.includes('<html')) { }
            else {
                //setLoaded(true);
                setUrl(`/static/profiles/${user._doc._id}`);
            }
            setLoaded(true);
        }).catch((err) => {
            setLoaded(true);
        });
        return (
            <div className={classes.root}>
                <CircularProgress style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: 'white',
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }} />
            </div>);
    }

    return (
        <div className={classes.root}>
            <input
                accept="image/*"
                className={classes.input}
                id="icon-button-file"
                type="file"
                onChange={(e) => {
                    var file = e.target.files[0];
                    if (file) {
                        authService.uploadProfile(file).then((x) => {
                            window.location.reload(true);
                        }).catch((err) => {
                            swal({ icon: 'error', title: err.response?.data?.message ?? err.message });
                        });
                    }
                    // setFile(file);
                }}
            />
            <label htmlFor="icon-button-file">
                <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                >
                    <Avatar
                        src={url}
                        className={classes.large}
                    />
                </IconButton>
            </label>
        </div>
    );
}
