import { CircularProgress, Typography, Box } from '@mui/material';
import { useState } from "react";
import AuthService from '../services/auth.service';
import { Link as RouterLink, Navigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
// layouts
import AuthLayout, { AuthLayoutMob } from '../layouts/AuthLayout';
// components
// import AccountPopover from '../layouts/dashboard/AccountPopover';

const RootStyle = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
});


export default function Topics() {
    const token = window.location.href.split('/').at(-1);
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState('');

    if (loading) {
        AuthService.validateToken(token).then((x) => {
            window.location.href = "/dashboard";
            setLoading(false);
        }).catch((err) => {
            setMessage('Token Expired or Invalid Token');
            setLoading(false);
        });
    }
    return (
        <>
            {loading === true && <CircularProgress style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                background: 'white',
                marginLeft: 'auto',
                marginRight: 'auto'
            }} />}
            {message != '' &&
                <div style={{ textAlign: 'center' }}>
                    <Typography>{message}</Typography>
                </div>
            }
        </>
    );
}