import { Link as RouterLink, Navigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Card, Link, Container, Typography } from '@mui/material';
// layouts
import AuthLayout, { AuthLayoutMob } from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import AuthService from '../services/auth.service';
import { ProfileForm } from '../components/authentication/profile';
import AccountPopover from '../layouts/dashboard/AccountPopover';
import { useState } from 'react';
import swal from 'sweetalert';
import { CircularProgress } from '@mui/material';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex'
    }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 464,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Profile() {
    const [loading, setLoading] = useState(true);
    const user = AuthService.getCurrentUser()?._doc;
    if (!user) {
        return <Navigate to="/login" />
    }

    if (loading) {
        AuthService.checkUserData().then((x) => {
            if (!x.data.user || x.data.user?.password !== AuthService.getCurrentUser()._doc.password) {
                AuthService.logout();
                swal({
                    title: 'You will be logged out now',
                    text: 'Your password is changed in an other device',
                    icon: 'error'
                }).then((x) => {
                    window.location.href = "login";
                });
            }
            else {
                const user = { ...AuthService.getCurrentUser(), _doc: x.data.user };
                if (localStorage.getItem('user')) {
                    localStorage.setItem('user', JSON.stringify(user));
                }
                else if (sessionStorage.getItem('user')) {
                    sessionStorage.setItem('user', JSON.stringify(user));
                }

                setLoading(false);
            }
        }).catch((err) => {
            AuthService.logout();
            window.location.href = "login";
        });

        return (
            <Box>
                <CircularProgress style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    background: 'white',
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }} />
            </Box>
        );
    }

    return (
        <RootStyle title="Profile | Vmeet">
            <AuthLayout>
                <AccountPopover />
            </AuthLayout>

            <MHidden width="smUp">
                <AuthLayoutMob>
                    <AccountPopover />
                </AuthLayoutMob>
            </MHidden>

            <MHidden width="mdDown">
                <SectionStyle>
                    <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                        {`Hi, ${AuthService.getCurrentUser()._doc.lastName}...`}
                        <br />
                        {'Manage your Profile'}

                    </Typography>
                    <img alt="register" src="/static/illustrations/illustration_register.png" />
                </SectionStyle>
            </MHidden>

            <Container>
                <ContentStyle>
                    <Box sx={{ mb: 5 }}>
                        <Typography variant="h4" gutterBottom>
                            Host Profile
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>
                            Edit Your Profile
                        </Typography>
                    </Box>

                    <ProfileForm user={user} />

                    {/* <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mt: 3 }}>
            By registering, I agree to Minimal&nbsp;
            <Link underline="always" sx={{ color: 'text.primary' }}>
              Terms of Service
            </Link>
            &nbsp;and&nbsp;
            <Link underline="always" sx={{ color: 'text.primary' }}>
              Privacy Policy
            </Link>
            .
          </Typography> */}
{/* 
                    <MHidden width="smUp">
                        <Typography variant="subtitle2" sx={{ mt: 3, textAlign: 'center' }}>
                            Already have an account?&nbsp;
                            <Link to="/host" component={RouterLink}>
                                Login
                            </Link>
                        </Typography>
                    </MHidden> */}
                </ContentStyle>
            </Container>
        </RootStyle>
    );
}
