import axios from "axios";
import authService from "./auth.service";

const API_URL = "https://vmeet.visualbench.com:8443/meeting/";
const RECORDING_URL = "https://recordings.visualbench.com/";

class MeetingService {
    createMeeting(data) {
        return axios
            .post(API_URL + "createMeeting",
                data
            );
    }
    editMeeting(data) {
        return axios
            .post(API_URL + "editMeeting",
                data
            );
    }
    getServerTime() {
        return axios
            .get(API_URL + "getDate");
    }
    getUserMeetings(id) {
        return axios.post(API_URL + "getMeetings", { id });
    }
    getUserPayments(id) {
        return axios.post(API_URL + "getPayments", { id });
    }
    getUserRecordings(id) {
        return axios.post(`${RECORDING_URL}recordings/get`, { teacherId: id }); //recordings.visualbench.com
    }
    getMeeting(id) {
        return axios.post(API_URL + "getMeeting", { id });
    }
    addNewContact(data) {
        return axios.post(API_URL + "saveContact", { data });
    }
    editContact(id, data) {
        return axios.post(API_URL + "editContact", { id, data });
    }
    getUserContacts(id) {
        return axios.post(API_URL + "getContacts", { id });
    }
    generateAccessToken(user, isHost) {
        return axios.post(API_URL + "generateAccessToken", { ...user, isHost });
    }
    changeStateOfMeeting(meetingId, state, extraParams) {
        return axios.post(API_URL + "changeStateOfMeeting", { id: meetingId, state: Number(state), extraParams });
    }
    deleteContact(id) {
        return axios.post(API_URL + "deleteContact", { id });
    }
    addContacts(contacts) {
        return axios.post(API_URL + "addContacts", { contacts });
    }
    async InitiatePayment(...params) {
        return axios.post(API_URL + "initiatePayment", { ...params, userId: authService.getCurrentUser()._doc._id });
    }
    async RejectPayment(...params) {
        return axios.post(API_URL + "rejectPayment", { ...params });
    }
    async VerifyPayment(...params) {
        return axios.post(API_URL + "verifyPayment", { ...params });
    }
    sendReintimatationEmail(meetingId) {
        const user = authService.getCurrentUser();
        if (!user) {
            window.location.href = "/login";
            window.location.reload();
        }
        else {
            return axios.post(API_URL + "reintimateParticipants", { id: meetingId, hostId: user._doc._id });
        }
    }
}

export default new MeetingService();