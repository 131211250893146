import { Link as RouterLink, Navigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Container, Typography } from '@mui/material';
// layouts
import AuthLayout, { AuthLayoutMob } from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { ParticipantLoginForm } from '../components/authentication/login';
import AuthService from '../services/auth.service';
import { useState } from 'react';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function ParticipantLogin() {
  const [forgotPassword, setForgotPassword] = useState(false);

  if (AuthService.getCurrentUser()) {
    return <Navigate to="/dashboard" />
  }
  else if (AuthService.getCurrentMeetUser()) {
    return <Navigate to="/meet" />
  }

  return (
    <RootStyle title="Join | Vmeet">
      <AuthLayout>
        Meeting Host? &nbsp;
        <Link variant="subtitle2" component={RouterLink} to="/login">
          <div style={{ display: 'contents' }}>Login</div>
        </Link>
      </AuthLayout>
      <AuthLayoutMob />

      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Join Meeting
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              {!forgotPassword ? 'Join Meeting' : 'Resend Passcode'}
            </Typography>
          </Stack>
          {/* <AuthSocial /> */}

          <ParticipantLoginForm forgotPassword={forgotPassword} setForgotPassword={setForgotPassword} />
          <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Meeting Host?&nbsp;
              <Link variant="subtitle2" component={RouterLink} to="/login">
                Login
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
