import * as Yup from 'yup';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment, FormLabel, RadioGroup, Radio } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AuthService from '../../../services/auth.service';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import ProfilePicture from './ProfilePicture';
import swal from 'sweetalert';

// ----------------------------------------------------------------------

export default function ProfileForm({ user }) {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [loginError, setLoginError] = useState("");
    const [changePassword, setChangePassword] = useState(false);
    const [file, setFile] = useState(null);

    const RegisterSchema = Yup.object().shape({
        firstName: Yup.string()
            .min(2, 'Too Short!')
            .max(14, 'Too Long!')
            .required('First name required'),
        lastName: Yup.string().min(2, 'Too Short!').max(14, 'Too Long!').required('Last name required'),
        email: Yup.string().email('Email must be a valid email address').required('Email is required'),
        password: Yup.string().min(8, "Password is too short - should be 8 letters minimum.")
            .matches(/(?=.*[0-9])/, "Password must contain a number.")
            .matches(
                /(?=.*[●!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])/,
                "Password must contain a symbol."
            )
    });

    const formik = useFormik({
        initialValues: {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            password: '',
            selectedOption: user.recordingType ?? 'server'
        },
        validationSchema: RegisterSchema,
        onSubmit: (data, { setSubmitting }) => {
            if (changePassword && data.password === "") {
                setSubmitting(false);
                setLoginError('Set a password');
                return null;
            }
            AuthService.updateUser(
                data.firstName,
                data.lastName,
                user._id,
                changePassword ? data.password : null,
                // data.selectedOption
            ).then(
                response => {
                    if (changePassword) {
                        AuthService.logout();
                        swal({
                            title: 'You will be logged out now',
                            text: 'You have changed your password',
                        }).then((x) => {
                            window.location.href = "login";
                        });
                    }
                    else {
                        if (localStorage.getItem('user')) {
                            var obj = JSON.parse(localStorage.getItem('user'));
                            obj._doc.firstName = data.firstName;
                            obj._doc.lastName = data.lastName;

                            localStorage.setItem('user', JSON.stringify(obj));
                        }
                        else if (sessionStorage.getItem('user')) {
                            var obj = JSON.parse(sessionStorage.getItem('user'));
                            obj._doc.firstName = data.firstName;
                            obj._doc.lastName = data.lastName;

                            sessionStorage.setItem('user', JSON.stringify(obj));
                        }
                        window.location.reload();
                        setSubmitting(false);
                    }
                    // AuthService.login(data.email, data.password, true).then(
                    //     () => {
                    //         navigate('/dashboard', { replace: true });
                    //     },
                    //     error => {
                    //         navigate('/hlogin', { replace: true });
                    //         window.location.reload();
                    //     }
                    // );
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setSubmitting(false);
                    setLoginError(resMessage);
                }
            ).catch((error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setSubmitting(false);
                setLoginError(resMessage);
            });
        }
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    <ProfilePicture file={file} setFile={setFile} />
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <TextField
                            fullWidth
                            label="First name"
                            {...getFieldProps('firstName')}
                            error={Boolean(touched.firstName && errors.firstName)}
                            helperText={touched.firstName && errors.firstName}
                        />

                        <TextField
                            fullWidth
                            label="Last name"
                            {...getFieldProps('lastName')}
                            error={Boolean(touched.lastName && errors.lastName)}
                            helperText={touched.lastName && errors.lastName}
                        />
                    </Stack>

                    <TextField
                        fullWidth
                        autoComplete="username"
                        type="email"
                        label="Email address"
                        disabled
                        {...getFieldProps('email')}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                    />

                    {/* <FormGroup sx={{ display: 'block' }}>
                        <FormLabel id="radio-buttons-group-label">Save Recordings to</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="radio-buttons-group-label"
                            name="selectedOption"
                            value={formik.values.selectedOption.toString()}
                            onChange={(event) => {
                                formik.setFieldValue('selectedOption', event.currentTarget.value)
                            }}
                        >
                            <FormControlLabel value="server" control={<Radio />} label="Server" />
                            <FormControlLabel value="local" control={<Radio />} label="Local Storage" />
                        </RadioGroup>
                                </FormGroup>*/}


                    <FormGroup aria-labelledby="switch-buttons-group-label" sx={{ display: 'block' }}>
                        <FormLabel id="switch-buttons-group-label">Communicate by</FormLabel>
                        <br />
                        <FormControlLabel control={<Switch disabled defaultChecked={!user.communications || typeof user.communications.mobile === 'undefined' || user.communications.mobile} />} label="SMS" />
                        <FormControlLabel control={<Switch disabled defaultChecked={!user.communications || typeof user.communications.email === 'undefined' || user.communications.email} />} label="Email" />
                        {/* <FormControlLabel control={<Switch disabled defaultChecked={!user.communications || typeof user.communications.whatsapp === 'undefined' || user.communications.whatsapp} />} label="Whatsapp" /> */}
                    </FormGroup>


                    <FormGroup>
                        <FormControlLabel control={<Checkbox defaultChecked={changePassword} onChange={(event) => setChangePassword(event.target.checked)} />} label="Change Password" />
                    </FormGroup>

                    {changePassword === true && (<TextField
                        fullWidth
                        autoComplete="current-password"
                        type={showPassword ? 'text' : 'password'}
                        label="Password"
                        {...getFieldProps('password')}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                    />)}

                    {!isSubmitting && loginError && (
                        <div style={{ color: "red" }}>
                            <span>{loginError}</span>
                        </div>
                    )}

                    <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                    >
                        SAVE
                    </LoadingButton>
                </Stack>
            </Form>
        </FormikProvider>
    );
}
