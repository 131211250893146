import axios from "axios";

const API_URL = "https://vvisionapi.visualbench.com/";

class AuthService {
    async login(email, password, remember, captchaToken) {
        const response = await axios
            .get(API_URL + `verifylogin?username=${email}&password=${password}`);
        if (response.data) {
            this.logout();
            var branchID = (response.data).split(",")[0];
            var orgID = (response.data).split(",")[1];

            if (!remember) {
                sessionStorage.setItem("user", JSON.stringify({
                    branchID,
                    orgID,
                    email,
                    password
                }));
            }
            else {
                localStorage.setItem("user", JSON.stringify({
                    branchID,
                    orgID,
                    email,
                    password
                }));
            }
        }
        return response.data;
    }

    async particpantLogin(userId, password, isMobile) {
        const response = await axios
            .post(API_URL + "participantlogin", {
                userId,
                password,
                isMobile
            });
        if (response.data.accessToken) {
            this.logout();
            sessionStorage.setItem("meetuser", JSON.stringify(response.data));
        }
    }

    logout() {
        localStorage.removeItem("user");
        localStorage.removeItem("meetuser");
        sessionStorage.removeItem("user");
    }

    register(firstName, lastName, email, password, captcha) {
        return axios.post(API_URL + "signup", {
            firstName,
            lastName,
            email,
            password,
            captcha
        });
    }
    updateUser(firstName, lastName, id, password/*, recordingType*/) {
        return axios.post(API_URL + "updateUser", {
            firstName,
            lastName,
            id,
            password,
            // recordingType
        });
    }

    getCurrentUser() {
        var user = JSON.parse(localStorage.getItem('user') ?? sessionStorage.getItem('user'));

        // if (!user && window.location.pathname != 'host') {
        //     window.location.href = "/login";
        //     window.location.reload();
        // }
        return user;
    }
    getCurrentMeetUser() {
        return JSON.parse(sessionStorage.getItem('meetuser'));
    }
    checkUserData() {
        return axios.post(API_URL + "verifyData", this.getCurrentUser()._doc);
    }
    uploadProfile(file) {
        const data = new FormData();
        data.append('avatar', file);
        data.append('id', this.getCurrentUser()._doc._id);

        return axios.post(`${API_URL}uploadProfile`, data);
    }
    initiatechangePassword(email, captcha) {
        return axios.post(API_URL + "initiatechangePassword", { email, captcha });
    }
    async validateToken(token) {
        var response = await axios
            .post(API_URL + "validateToken", {
                token
            });
        if (response.data.accessToken) {
            this.logout();
            var isReg = response.data.isReg;
            delete response.data.isReg;
            sessionStorage.setItem("user", JSON.stringify(response.data));
            
            if (!isReg)
                sessionStorage.setItem('message', "Go to Profile on the top right corner to change your password");
        }
        return response.data;
    }
    resendParticipantEmail(userId, isEmail, captcha) {
        return axios.post(API_URL + "resendParticipantEmail", { userId, isEmail, captcha });
    }
}

export default new AuthService();