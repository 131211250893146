import * as Yup from 'yup';
import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AuthService from '../../../services/auth.service';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TimePicker from '@mui/lab/TimePicker';
import Button from '@mui/material/Button';
import DateTimePicker from '@mui/lab/DateTimePicker';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { PopupData } from './PopupData';
import * as XLSX from 'xlsx';
import swal from 'sweetalert';
import MeetingService from '../../../services/meeting.service';
import Switch from '@mui/material/Switch';
import ContactChooserModal from './components/ContactsChooserModal';
import { Box, Card, Link, Container, Typography, CircularProgress } from '@mui/material';

Date.prototype.yyyymmdd = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [this.getFullYear(),
    (mm > 9 ? '' : '0') + mm,
    (dd > 9 ? '' : '0') + dd
    ].join('-');
};

function generatePasscode() {
    var length = 4,
        charset = "0123456789",
        retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
}

function getParameterCaseInsensitive(object, key) {
    try {
        return object[Object.keys(object)
            .find(k => k.toLowerCase() === key.toLowerCase())
        ];
    }
    catch {
        return null;
    }
}

function IsValidMobile(mobile) {
    var val = mobile + ''
    if (/^\d{10}$/.test(val)) {
        return true;
    } else {
        return false
    }
}

function IsValidEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

function getValidContactsAndEmails(data, passcode) {
    var finalData = { validEmails: [], validMobile: [], invalidEmails: [], invalidMobile: [], validSet: [] }
    data.map((x) => {
        var set = {};
        var mob = getParameterCaseInsensitive(x, 'Mobile');
        var email = getParameterCaseInsensitive(x, 'Email');
        if (typeof mob !== 'undefined' && (mob + '').trim() !== '')
            if (mob && IsValidMobile(mob)) {
                if (finalData.validMobile.find((x) => { return x === mob }) || finalData.invalidMobile.find((x) => { return x === mob })) {
                    throw new Error('Duplicate Mobiles found - ' + mob);
                }
                else {
                    set.Mobile = mob;
                    finalData.validMobile.push(mob);
                }
            }
            else {
                if (finalData.validMobile.find((x) => { return x === mob }) || finalData.invalidMobile.find((x) => { return x === mob })) {
                    throw new Error('Duplicate Mobiles found - ' + mob);
                }
                else
                    finalData.invalidMobile.push(mob);
            }
        if (typeof email !== 'undefined' && (email + '').trim() !== '')
            if (email && IsValidEmail(email)) {
                if (finalData.validEmails.find((x) => { return x === email }) || finalData.invalidEmails.find((x) => { return x === email })) {
                    throw new Error('Duplicate Emails found - ' + email);
                }
                else {
                    set.Email = email;
                    finalData.validEmails.push(email);
                }
            }
            else {
                if (finalData.validEmails.find((x) => { return x === email }) || finalData.invalidEmails.find((x) => { return x === email })) {
                    throw new Error('Duplicate Emails found - ' + email);
                }
                else
                    finalData.invalidEmails.push(email);
            }
        if (Object.keys(set).length !== 0) {
            set.Name = getParameterCaseInsensitive(x, 'Name');
            set.Passcode = passcode ?? (x.Passcode ?? generatePasscode());
            finalData.validSet.push(set);
        }
    });
    return finalData;
}
// ----------------------------------------------------------------------

export default function VideoForm({ meetingData }) {
    const [loginError, setLoginError] = useState("");
    // const [title, setTitle] = useState("");
    const [startDate, setStartDate] = useState(meetingData?.startTime ? new Date(meetingData?.startTime) : new Date());
    const [startTime, setStartTime] = useState(meetingData?.startTime ? new Date(meetingData?.startTime) : "");
    const [EndTime, setEndTime] = useState(meetingData?.endTime ? new Date(meetingData?.endTime) : "");
    const [InformTime, setInformTime] = useState(meetingData?.informTime ? ((meetingData?.informTime === "now" || meetingData?.informTime === "no") ? '' : new Date(meetingData?.informTime)) : '');
    // const [selectedRadio, setSelectedRadio] = useState('0');
    const [informRadio, setInformRadio] = useState(meetingData?.informTime ? (meetingData?.informTime === "now" ? '0' : meetingData?.informTime === "no" ? '2' : '1') : '0');
    const [ParticipantChangeRadio, setParticipantChangeRadio] = useState('0');
    // const [contacts, setContacts] = useState({ validEmails: [], validMobile: [], invalidEmails: [], invalidMobile: [], validSet: [] });
    const [popupData, setPopupData] = useState(null);
    const [checked, setChecked] = React.useState(meetingData?.settings?.shareScreen === false ? false : true);
    const [Passcodechecked, setPasscodeChecked] = React.useState(!meetingData?.settings?.samePasscode ? false : true);
    const [samePasscode, setSamePasscode] = useState(meetingData?.settings?.samePasscode ?? 0);
    const [openContactChooser, setOpenContactChooser] = useState(false);
    const [SelectedParticipants, setSelectedParticipants] = useState([]);
    const [ImportParticipantData, setImportParticipantData] = useState([]);
    const [addedParticipants, setAddedParticipants] = useState([]);
    const [loading, setLoading] = React.useState(Boolean(meetingData));
    const [isInformed, setIsInformed] = useState(undefined);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const handlePasscodeCheckedChange = (event) => {
        setPasscodeChecked(event.target.checked);
    };

    if (meetingData?.contacts && SelectedParticipants.length === 0) {
        var selectedContacts = [...meetingData.contacts];
        var idsArray = [];
        selectedContacts.map((x) => {
            idsArray.push(x.id);
        });
        meetingData.idsArray = idsArray;
        setImportParticipantData(idsArray);
        setSelectedParticipants(selectedContacts);
        // setContacts(getValidContactsAndEmails(meetingData?.contacts, Passcodechecked ? samePasscode : undefined));
    }

    const GetUser = () => AuthService.getCurrentUser();

    var tzoffset = (new Date()).getTimezoneOffset(); //offset in milliseconds
    var localDate = (new Date(Date.now() - tzoffset));
    localDate.setHours(localDate.getHours() + 1);
    // var minDate = localDate.toISOString();

    if (startTime === "") {
        setStartTime(localDate);
    }

    const RegisterSchema = Yup.object().shape({
        title: Yup.string().required('Title is required').max(30, 'Title cannot be more than 30 characters'),
        description: Yup.string()
    });

    const formik = useFormik({
        initialValues: {
            title: meetingData?.title ?? '',
            description: meetingData?.description ?? ''
        },
        validationSchema: RegisterSchema,
        onSubmit: (data, { setSubmitting }) => {
            try {
                var User = GetUser()._doc;
                if (!User) {
                    setLoginError('Not Logged in');
                    setTimeout(function () {
                        window.location.href = "hlogin";
                        window.location.reload();
                    }, 3000);
                }
                else {
                    const { title, description } = data;

                    if (typeof SelectedParticipants[0] === 'undefined') {
                        swal('Failed', 'No Contacts found', 'error');
                        return false;
                    }

                    // var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
                    // var localDateISOTime = (new Date(Date.now() - tzoffset));
                    // var localISOTime = localDateISOTime.setHours().toISOString().slice(0, -1);
                    // var minDate = localISOTime.substring(0, localISOTime.length - 1).split('.')[0];
                    // minDate = minDate.substring(0, minDate.length - 3);
                    var nowDate = new Date();
                    nowDate.setHours(0, 0, 0, 0);
                    var newStartDate = new Date(startDate);
                    newStartDate.setHours(0, 0, 0, 0);
                    if (newStartDate == nowDate) {
                        setSubmitting(false);
                        return swal('Current Date is GREATER than Meeting Date', '', 'error');
                    }
                    else {
                        var date = startDate.yyyymmdd();
                        var finalStartTime = [date, startTime.toISOString().split('T')[1]].join('T');
                        var finalEndTime = [date, EndTime.toISOString().split('T')[1]].join('T');

                        if (new Date(finalStartTime) < new Date()) {
                            setSubmitting(false);
                            return swal('Current Time is GREATER than Meeting Start time', '', 'error');
                        }
                        else if (informRadio === '1' && new Date(finalStartTime) < InformTime) {
                            setSubmitting(false);
                            return swal('Inform time is GREATER than Meeting Start time', '', 'error');
                        }
                        else if (new Date(finalStartTime) > new Date(finalEndTime)) {
                            setSubmitting(false);
                            return swal('Meeting Start time is GREATER than Meeting End time', '', 'error');
                        }
                        var contactSet = null;
                        // var addedParticipants = [];
                        // if (!meetingData) {
                        // var changedContacts = [];
                        contactSet = [...SelectedParticipants];
                        contactSet.map((x) => {
                            if (Passcodechecked) {
                                x.Passcode = samePasscode;
                            }
                            else {
                                x.Passcode = generatePasscode();
                            }
                            return x;
                        });
                        // }

                        var finalObj = {
                            title,
                            description,
                            startTime: finalStartTime,
                            endTime: finalEndTime,
                            informTime: informRadio === '1' ? InformTime : (informRadio === '0' ? 'now' : 'no'),
                            contacts: contactSet,
                            hostId: User._id,
                            status: 0,
                            clientDate: new Date(),
                            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                            isInformed: isInformed,
                            settings: {
                                shareScreen: checked
                            }
                        };

                        if (Passcodechecked) {
                            finalObj.settings.samePasscode = samePasscode;
                        }

                        if (meetingData) {
                            finalObj._id = meetingData._id;
                            if (meetingData && addedParticipants.length > 0 && isInformed && informRadio !== '2') {
                                finalObj.ParticipantChangeOption = ParticipantChangeRadio;
                                if (ParticipantChangeRadio === '0') {
                                    finalObj.addedParticipants = addedParticipants;
                                }
                            }

                            MeetingService.editMeeting(finalObj).then((x) => {
                                swal('Meeting Edit Successfully', 'Go to "Manage Meetings" in the menu to start the meeting', 'success').then((x) => {
                                    window.location.reload();
                                });
                                setSubmitting(false);
                            }).catch((error) => {
                                swal('Error', error?.response?.data?.message ?? error.message, 'error').then(() => { window.location.href = "/meetings"; });
                                setSubmitting(false);
                            });
                        }
                        else {
                            MeetingService.createMeeting(finalObj).then((x) => {
                                swal('Meeting Created Successfully', 'Go to "Manage Meetings" in the menu to start the meeting', 'success').then((x) => {
                                    window.location.reload();
                                });
                                setSubmitting(false);
                            }).catch((error) => {
                                swal('Error', error?.response?.data?.message ?? error.message, 'error');
                                setSubmitting(false);
                            });
                        }
                    }

                    // call('CreateMeeting', finalObj).then((res) => {
                    //     swal('Meeting Created', '', 'success');
                    // }).catch((err) => {
                    //     swal('Meeting Not Created', '', 'error');
                    // });
                }
            }
            catch (e) {
                setSubmitting(false);
                setLoginError(e.message);
            }
        }
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    if (loading) {
        MeetingService.getMeeting(meetingData._id).then((x) => {
            setLoading(false);
            setIsInformed(Boolean(x.data.data.informed));
        }).catch((err) => {
            AuthService.logout();
            window.location.href = "login";
        });

        return (
            <Box>
                <CircularProgress style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: 'white',
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }} />
            </Box>
        );
    }

    // return (
    //     <LocalizationProvider dateAdapter={AdapterDateFns}>
    //         <DatePicker
    //             label="Basic example"
    //             value={value}
    //             onChange={(newValue) => {
    //                 setValue(newValue);
    //             }}
    //             renderInput={(params) => <TextField {...params} />}
    //         />
    //     </LocalizationProvider>
    // );

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    <TextField
                        fullWidth
                        label="Title"
                        {...getFieldProps('title')}
                        error={Boolean(touched.title && errors.title)}
                        helperText={touched.title && errors.title}
                    />
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Meeting Date"
                                value={startDate}
                                onChange={(newValue) => {
                                    setStartDate(newValue);
                                }}
                                inputFormat="dd/MM/yyyy"
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>

                        {/* <FormControl component="fieldset">
                        <FormLabel component="legend">Meeting Start time</FormLabel>
                        <RadioGroup row aria-label="meetingTime" name="row-radio-buttons-group" onChange={(event) => { setSelectedRadio(event.target.value) }}>
                            <FormControlLabel value="0" control={<Radio />} label="Now" />
                            <FormControlLabel value="1" control={<Radio />} label="Set Time" />
                        </RadioGroup>
                    </FormControl> */}

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <TimePicker
                                label="Meeting Start Time"
                                value={startTime}
                                onChange={(newValue) => {
                                    setStartTime(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <TimePicker
                                label="Meeting End Time"
                                value={EndTime === "" ? (function () {
                                    var tzoffset = (new Date()).getTimezoneOffset(); //offset in milliseconds
                                    var localDate = (new Date(Date.now() - tzoffset));
                                    localDate.setHours(localDate.getHours() + 2);
                                    //var minDate1 = localDate.toISOString();
                                    // var minDate1 = localISOTime.substring(0, localISOTime.length - 1).split('.')[0];
                                    // minDate1 = minDate1.substring(0, minDate1.length - 3);
                                    setEndTime(localDate);
                                    return localDate;
                                })() : EndTime}
                                onChange={(newValue) => {
                                    setEndTime(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Stack>
                    <TextField
                        label="Description (optional)"
                        multiline
                        {...getFieldProps('description')}
                    />
                    <FormControlLabel control={<Switch checked={Passcodechecked}
                        onChange={handlePasscodeCheckedChange}
                        inputProps={{ 'aria-label': 'controlled' }} />} label={"Use same passcode for all participants " + (Passcodechecked ? (function () {
                            if (samePasscode === 0) {
                                var generate = generatePasscode();
                                setSamePasscode(generate);
                                return generate;
                            }
                            return samePasscode;
                        })() : '')} />

                    <FormControlLabel control={<Switch checked={checked}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }} />} label="Enable participant to share screen" />

                    <Button
                        variant="outlined"
                        component="label"
                        style={{ width: '65%', marginLeft: 'auto', marginRight: 'auto' }}
                        onClick={() => {
                            setOpenContactChooser(true);
                        }}
                    >
                        {SelectedParticipants.length > 0 ? `Selected ${SelectedParticipants.length} Participants` : 'Choose Participants'}
                    </Button>
                    {/* <div> */}
                    {/* {contacts.invalidEmails.length + contacts.validEmails.length > 0 && (<div style={{ marginTop: '10px' }}><Button onClick={() => { setPopupData({ title: 'Valid Emails', data: contacts.validEmails }) }} variant="text">{'Valid Emails: ' + contacts.validEmails.length}</Button><Button onClick={() => { setPopupData({ title: 'Invalid Emails', data: contacts.invalidEmails }) }} variant="text">{'Invalid Emails: ' + contacts.invalidEmails.length}</Button><br /></div>)}
                    {contacts.invalidMobile.length + contacts.validMobile.length > 0 && (<div style={{ marginTop: '0px' }}><Button onClick={() => { setPopupData({ title: 'Valid Mobiles', data: contacts.validMobile }) }} variant="text">{'Valid Mobiles: ' + contacts.validMobile.length}</Button><Button onClick={() => { setPopupData({ title: 'Invalid Mobiles', data: contacts.invalidMobile }) }} variant="text">{'Invalid Mobiles: ' + contacts.invalidMobile.length}</Button></div>)} */}
                    {/* </div> */}
                    {/* {selectedRadio === '1' && (
                        <div> */}
                    <Stack style={{ marginTop: '10px' }} direction={{ xs: 'column', sm: 'row' }} spacing={2}>


                        {/* </div>
)} */}                    {(!meetingData || (ParticipantChangeRadio !== '2')) && <FormControl style={{ width: (meetingData && informRadio === '1') ? '200px' : 'auto' }} component="fieldset">
                            <FormLabel component="legend">Inform to Participants</FormLabel>
                            <RadioGroup defaultValue={informRadio} row aria-label="informTime" name="row-radio-buttons-group" onChange={(event) => { setInformRadio(event.target.value) }}>
                                <FormControlLabel value="0" control={<Radio />} label="Now" />
                                <FormControlLabel value="1" control={<Radio />} label="Set Time" />
                                {meetingData && isInformed && <FormControlLabel value="2" control={<Radio />} label="Don’t Inform" />}
                            </RadioGroup>
                        </FormControl>}
                        {(!meetingData || (ParticipantChangeRadio !== '2')) && informRadio === '1' && (<LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                                label="Inform to Participants"
                                value={InformTime === "" ? (function () {
                                    var tzoffset = (new Date()).getTimezoneOffset(); //offset in milliseconds
                                    var localDate = (new Date(Date.now() - tzoffset));
                                    localDate.setMinutes(localDate.getMinutes() + 30);
                                    //var minDate1 = localDate.toISOString();

                                    // var localISOTime = (new Date(Date.now() - tzoffset.))toISOString().slice(0, -1);
                                    // var minDate1 = localISOTime.substring(0, localISOTime.length - 1).split('.')[0];
                                    // minDate1 = minDate1.substring(0, minDate1.length - 3);
                                    setInformTime(localDate);
                                    return localDate;
                                })() : InformTime}
                                inputFormat="dd/MM/yyyy hh:mm a"
                                onChange={(newValue) => {
                                    setInformTime(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>)}
                    </Stack>

                    {meetingData && addedParticipants.length > 0 && isInformed && informRadio !== '2' && (<FormControl component="fieldset">
                        <FormLabel component="legend">Inform to Participants</FormLabel>
                        <RadioGroup defaultValue={ParticipantChangeRadio} row aria-label="informTime" name="row-radio-buttons-group" onChange={(event) => { setParticipantChangeRadio(event.target.value) }}>
                            <FormControlLabel value="0" control={<Radio />} label="Inform to newly added participants only" />
                            <FormControlLabel value="1" control={<Radio />} label="Inform to all participants again" />
                        </RadioGroup>
                    </FormControl>)}
                    {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <TextField
                            fullWidth
                            label="First name"
                            {...getFieldProps('firstName')}
                            error={Boolean(touched.firstName && errors.firstName)}
                            helperText={touched.firstName && errors.firstName}
                        />

                        <TextField
                            fullWidth
                            label="Last name"
                            {...getFieldProps('lastName')}
                            error={Boolean(touched.lastName && errors.lastName)}
                            helperText={touched.lastName && errors.lastName}
                        />
                    </Stack> */}

                    {!isSubmitting && loginError && (
                        <div style={{ color: "red" }}>
                            <span>{loginError}</span>
                        </div>
                    )}

                    <LoadingButton
                        style={{ width: '50%', marginLeft: 'auto', marginRight: 'auto' }}
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                    >
                        {meetingData ? 'EDIT' : 'CREATE'}
                    </LoadingButton>
                </Stack>
            </Form>
            {popupData && (<PopupData title={popupData.title} data={popupData.data} cancelClick={setPopupData}></PopupData>)}
            {openContactChooser && <ContactChooserModal idsArray={meetingData?.idsArray} data={ImportParticipantData} title={'Choose Participants'} onClose={(val, value, addedParticipants) => { if (typeof val !== 'undefined') { setImportParticipantData(value); setSelectedParticipants(val); setAddedParticipants(addedParticipants); } setOpenContactChooser(false) }} />}
        </FormikProvider>
    );
}
