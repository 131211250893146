import { Component } from "react";
import AuthService from "./auth.service";

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

class AuthVerify extends Component {
  constructor(props) {
    super(props);

    //props.history.listen(() => {
    const user = AuthService.getCurrentUser();

    // if (user) {
    //   const decodedJwt = parseJwt(user.accessToken);

    //   if (decodedJwt.exp * 1000 < Date.now()) {
    //     props.logOut();
    //   }
    // }
    //});
  }

  render() {
    return null;
  }
}

export default AuthVerify;