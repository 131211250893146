import * as Yup from 'yup';
import { useState, useRef } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AuthService from '../../../services/auth.service';
import ReCAPTCHA from "react-google-recaptcha";
import swal from 'sweetalert';
// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();
  const recaptchaRef = useRef(null);
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState("");

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(14, 'Too Long!')
      .required('First name required'),
    lastName: Yup.string().min(2, 'Too Short!').max(14, 'Too Long!').required('Last name required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required').min(8, "Password is too short - should be 8 letters minimum.")
      .matches(/(?=.*[0-9])/, "Password must contain a number.")
      .matches(
        /(?=.*[●!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])/,
        "Password must contain a symbol."
      )
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: async (data, { setSubmitting }) => {
      const captchaToken = await recaptchaRef.current.getValue();

      if (captchaToken === '') {
        setSubmitting(false);
        setLoginError('Complete the CAPTCHA');
      }
      else {
        AuthService.register(
          data.firstName,
          data.lastName,
          data.email,
          data.password,
          captchaToken
        ).then(
          response => {
            swal({
              title: 'Registration Link sent to Email',
              icon: 'success'
            }).then(() => {
              window.location.reload();
            })
          },
          error => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            setSubmitting(false);
            setLoginError(resMessage);
          }
        ).catch((error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setSubmitting(false);
          setLoginError(resMessage);
        });
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="First name"
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />

            <TextField
              fullWidth
              label="Last name"
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <div style={{ paddingBottom: '10px' }}>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={'6LewRVAdAAAAAD94PmgWKoCGg79pwzoWwhq86Z-9'}
            />
          </div>

          {!isSubmitting && loginError && (
            <div style={{ color: "red" }}>
              <span>{loginError}</span>
            </div>
          )}

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Register
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
