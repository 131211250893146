import React, { useState, useEffect } from 'react';
import {
    Field,
    Select,
    Box,
    FieldGroup,
    Button,
    TextField,
    MenuItem
} from '@mui/material';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import AuthService from '../../services/auth.service';
import MeetingService from '../../services/meeting.service';

const MySwal = withReactContent(Swal);

var jQuery = window.jQuery;

var finalValues = {};

function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}

const callWithPromise = (method, ...params) => new Promise(async (resolve, reject) => {
    try {
        if (method === 'InitiatePayment') {
            resolve((await MeetingService.InitiatePayment(...params)).data);
        }
        else if (method === 'RejectPayment') {
            resolve((await MeetingService.RejectPayment(...params)).data);
        }
        else if (method === 'VerifyPayment') {
            resolve((await MeetingService.VerifyPayment(...params)).data);
        }
    }
    catch {
        reject('Error');
    }
});

export default function PayNowForm({ closeClick }) {
    const [currency, setCurrency] = useState('INR');
    const user = AuthService.getCurrentUser()._doc;

    var refreshSubscription = function () {
        let users = Number(document.getElementById('users').value);
        let months = Number(document.getElementById('months').value);
        let additionalSpace = Number(document.getElementById('additionalSpace').value);

        let totalsubscription, trxnfees, priceCurrency;

        if (currency === 'INR') {
            totalsubscription = Number((users * months * 25) + (additionalSpace * 20 * months)); // 25 is cost of per user and 20 is cost of per GB in Rupees
            trxnfees = Number(Number(0.03 * totalsubscription).toFixed(2));
            priceCurrency = "₹";
        }
        else {
            totalsubscription = Number(Number((users * months * 0.45) + (additionalSpace * 0.5 * months)).toFixed(2)); // 0.45 is cost of per user and 0.5 is cost of per GB in Rupees
            trxnfees = Number(Number(0.03 * totalsubscription).toFixed(2));
            priceCurrency = "$";
        }

        jQuery('#totalsubscription').text(priceCurrency + totalsubscription);
        jQuery('#trxnfees').text(priceCurrency + trxnfees);
        jQuery('#toPay').text(priceCurrency + (trxnfees + totalsubscription));
        jQuery('#additionalSpacespan').text(users);
        jQuery('#additionalSpacespanTotal').text(users + additionalSpace);

        finalValues = { users, months, additionalSpace, priceCurrency: currency };
    }

    var openedSwal = () => {
        jQuery('#users').change(function (node) {
            let curval = Number(node.currentTarget.value);

            if (curval < 0) {
                return alert('Cannot be negative');
            }
            if (curval < 5) {
                return alert('Users can not be less than 4');
            }
            if (curval % 1 != 0) { //It is Decimal
                return alert('Cannot Enter Decimals');
            }
            if (curval > 300) {
                return alert('Users can not be more than 300');
            }
            // alert('called');
            // console.log(node.currentTarget.value);
            jQuery('#additionalSpacespan').text(node.currentTarget.value)
            refreshSubscription();
        });
        jQuery('#months').change(function (node) {
            let curval = Number(node.currentTarget.value);

            if (curval < 0) {
                return alert('Cannot be negative');
            }
            if (curval % 1 != 0) { //It is Decimal
                return alert('Cannot Enter Decimals');
            }
            if (curval > 15) {
                return alert('Months can not be more than 15');
            }

            refreshSubscription();
            // alert('called');
            // console.log(node.currentTarget.value);
            // jQuery('#additionalSpace').text(node.currentTarget.value)
        });
        jQuery('#additionalSpace').change(function (node) {
            let curval = Number(node.currentTarget.value);

            if (curval < 0) {
                return alert('Cannot be negative');
            }
            if (curval > 1000) {
                return alert('Maximum additional space is 1TB');
            }
            if (curval % 1 != 0) { //It is Decimal
                return alert('Cannot Enter Decimals');
            }

            refreshSubscription();
            // alert('called');
            // console.log(node.currentTarget.value);
            // jQuery('#additionalSpace').text(node.currentTarget.value)
        });
        refreshSubscription();
    };
    //log.info("AccountProfileForm + ", availableRoles);

    MySwal.fire({
        didOpen: () => {
            openedSwal();
        },
        confirmButtonText: 'Proceed To Pay',
        title: (
            <p>{'Pay Now'}</p>
        ),
        html: (<div>
            <div style={{ display: 'flex' }}>
                <div style={{ width: '45%', marginRight: '10%' }}>
                    {/* <h4>{('No of Users')}</h4> */}
                    <br />
                    <TextField
                        flexGrow={1}
                        defaultValue={10}
                        max="300"
                        label='No of Users'
                        type="number" onKeyPress={(event) => { return (event.charCode >= 48 && event.charCode <= 57) || event.charCode === 13 }} id="users" name="users" min="5"
                    />
                </div>

                <div style={{ width: '45%' }}>
                    <br />
                    {/* <h1>{('No of Months')}</h1> */}
                    <TextField
                        flexGrow={1}
                        defaultValue="1" type="number"
                        label='No of Months'
                        max="15"
                        id="months" onKeyPress={(event) => { return (event.charCode >= 48 && event.charCode <= 57) || event.charCode === 13 }} name="months" min="1"
                    />
                </div>
            </div>
            <div style={{ display: 'flex' }}>
                <div style={{ width: '45%' }}>
                    <br />
                    <br />
                    {/* <h1>{('Additional Recording Space')}</h1> */}
                    <div>
                        <TextField
                            defaultValue="0" flexGrow={1} type="number"
                            label="Additional Recording Space"
                            id="additionalSpace" onKeyPress={(event) => { return (event.charCode >= 48 && event.charCode <= 57) || event.charCode === 13 }} name="months" min="0" max="1000"
                        />
                        {/* &nbsp;
									<div style={{ width: 'auto', minWidth: '40%' }}>
										+ <span id="additionalSpacespan">10</span>GB =
										<span id="additionalSpacespanTotal"></span>GB
									</div> */}
                    </div>
                </div>
                <div style={{ width: '45%', display: 'contents' }}>
                    &nbsp;<div style={{ display: 'grid', fontSize: '18px' }}>
                        <h1>{('')}</h1>
                        &nbsp;
                        <div>
                            &nbsp;&nbsp;+&nbsp; <span id="additionalSpacespan"></span>GB&nbsp; =&nbsp;
                            <span id="additionalSpacespanTotal"></span>GB
                        </div>
                    </div>
                </div>
            </div>
            <div>
                {/* <h1>{('Currency')}</h1> */}
                <br />
                <div>
                    <p style={{ marginBottom: '0rem' }}>Currency</p>
                    <Select
                        value={currency}
                        onChange={(event) => { setCurrency(event.target.value); }}
                        label="Currency"
                        placeholder={('Select Currency')}
                        flexShrink={1}
                    ><MenuItem value={'INR'}>INR</MenuItem><MenuItem value={'USD'}>USD</MenuItem></Select>
                </div>
            </div>
            <div>
                <span style={{ fontSize: "18px" }}>Subscribing charge - </span>
                <span style={{ fontSize: "20px" }} id="totalsubscription"></span>
                <br />
                <span style={{ fontSize: "18px" }}>Transaction fees &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- </span>
                <span style={{ fontSize: "20px" }} id="trxnfees"></span>
                <br />
                <br />

                <div style={{ fontSize: "24px" }}>
                    <span>PAY &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- </span>
                    <span id="toPay"></span>
                </div>
            </div>
        </div>)
    }).then(async (x) => {
        if (x.isConfirmed) {
            const res = await loadScript(
                "https://checkout.razorpay.com/v1/checkout.js"
            );

            if (!res) {
                alert("Razorpay SDK failed to load. Are you online?");
                return;
            }

            // creating a new order
            let result;
            try {
                result = await callWithPromise('InitiatePayment', finalValues);
            }
            catch (err) {
                return alert(err.message);
            }

            if (!result) {
                alert("Server error. Are you online?");
                return;
            }

            // Getting the order details back
            const { amount, id: order_id, currency } = result;

            const options = {
                key: "rzp_live_uERTDl4ixhkVAL", // Enter the Key ID generated from the Dashboard
                amount: amount.toString(),
                currency: currency,
                name: "Visual Bench Technologies",
                description: "Subscription",
                image: "http://vmeet.visualbench.com/static/logo.png",
                order_id: order_id,
                handler: async function (response) {
                    const data = {
                        orderCreationId: order_id,
                        razorpayPaymentId: response.razorpay_payment_id,
                        razorpayOrderId: response.razorpay_order_id,
                        razorpaySignature: response.razorpay_signature,
                    };

                    const result = await callWithPromise('VerifyPayment', data);

                    if (result.msg === 'success') {
                        window.location.reload();
                    }
                    // alert(result.msg);
                },
                prefill: {
                    name: user.firstName + ' ' + user.lastName,
                    email: user.email,
                },
                theme: {
                    color: "#798e9b",
                },
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.on('payment.failed', function (response) {
                callWithPromise('RejectPayment', { id: response.error.metadata.order_id, reason: response.error.reason });
            });

            paymentObject.open();
        }
        closeClick(false);
    });

    return null;
}
