import * as Yup from 'yup';
import { useState, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
    Link,
    Stack,
    Checkbox,
    TextField,
    IconButton,
    InputAdornment,
    FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AuthService from '../../../../services/auth.service';
import Typography from '@mui/material/Typography';
import ReCAPTCHA from "react-google-recaptcha";
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import MeetingService from '../../../../services/meeting.service';
import swal from 'sweetalert';

// ----------------------------------------------------------------------

function IsValidMobile(mobile) {
    var val = mobile + ''
    if (/^\d{10}$/.test(val)) {
        return true;
    } else {
        return false
    }
}

export default function ForgotPasswordForm({ data: ContactsList, defaultValues }) {
    const recaptchaRef = useRef(null);
    const navigate = useNavigate();
    // const [showPassword, setShowPassword] = useState(false);
    const [loginError, setLoginError] = useState("");
    const [isProcessing, setIsProcessing] = useState(false);
    // const [isEmailSent, setIsEmailSent] = useState('');

    const LoginSchema = Yup.object().shape({
        name: Yup.string().required('Name is Required'),
        email: Yup.string().email('Email must be a valid email address'),
        mobile: Yup.string()
    });

    const formik = useFormik({
        initialValues: {
            email: defaultValues.email ?? '',
            mobile: defaultValues.mobile ?? '',
            name: defaultValues.name ?? ''
        },
        validationSchema: LoginSchema,
        onSubmit: async (data) => {
            setIsProcessing(true);

            var checkDuplicateObj = {};
            var user = AuthService.getCurrentUser()?._doc;

            if (!user) {
                window.location.href = '/dashboard';
                window.location.reload();
            }

            if (data.mobile === '' && data.email === '') {
                setIsProcessing(false);
                return setLoginError('Please add either email or mobile');
            }
            if (data.mobile != '') {
                if (!IsValidMobile(data.mobile)) {
                    setIsProcessing(false);
                    return setLoginError('Invalid mobile');
                }
                checkDuplicateObj.mobile = data.mobile;
            }
            if (data.email != '') {
                checkDuplicateObj.email = data.email.toLowerCase().trim();
            }

            for (var i = 0; i < ContactsList.length; i++) {
                if (typeof checkDuplicateObj.mobile !== 'undefined' && ContactsList[i].mobile === checkDuplicateObj.mobile && defaultValues.mobile !== ContactsList[i].mobile) {
                    setIsProcessing(false);
                    return setLoginError('Mobile is already given to another participant');
                }
                if (typeof checkDuplicateObj.email !== 'undefined' && ContactsList[i].email === checkDuplicateObj.email && defaultValues.email !== ContactsList[i].email) {
                    setIsProcessing(false);
                    return setLoginError('Email is already given to another participant');
                }
            }

            checkDuplicateObj.hostId = user._id;
            checkDuplicateObj.name = data.name;

            if (typeof defaultValues.name !== 'undefined') {
                MeetingService.editContact(defaultValues._id, checkDuplicateObj).then((x) => {
                    swal({
                        title: 'Participant Edited Successfully',
                        icon: 'success'
                    }).then(() => {
                        window.location.reload();
                    });
                }).catch((err) => {
                    swal({
                        title: err?.response?.data?.message ?? 'Some Error Occurred',
                        icon: 'error'
                    }).then(() => {
                        window.location.reload();
                    })
                });
            }
            else {
                MeetingService.addNewContact(checkDuplicateObj).then((x) => {
                    swal({
                        title: 'Participant Saved Successfully',
                        icon: 'success'
                    }).then(() => {
                        window.location.reload();
                    });
                }).catch((err) => {
                    swal({
                        title: 'Some Error Occurred',
                        icon: 'error'
                    }).then(() => {
                        window.location.reload();
                    })
                });
            }
            // const captchaToken = await recaptchaRef.current.getValue();
            // recaptchaRef.current.reset();

            // if (captchaToken === '') {
            // setSubmitting(false);
            // return setLoginError('Complete the CAPTCHA');
            // }

            // AuthService.initiatechangePassword(data.email, captchaToken);
            // setIsEmailSent(data.email);
            // setSubmitting(false);
        }
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Box
                    sx={{
                        '& > :not(style)': { m: 1 },
                        display: 'grid'
                    }}
                >
                    <TextField label="Name" error={Boolean(touched.name && errors.name)} helperText={touched.name && errors.name} variant="outlined" {...getFieldProps('name')} />
                    <TextField label="Email" error={Boolean(touched.email && errors.email)} helperText={touched.email && errors.email} variant="outlined" {...getFieldProps('email')} />
                    <TextField label="Mobile" error={Boolean(touched.mobile && errors.mobile)} helperText={touched.mobile && errors.mobile} variant="outlined" {...getFieldProps('mobile')} />
                    {!isProcessing && loginError && (
                        <div style={{ color: "red" }}>
                            <span>{loginError}</span>
                        </div>
                    )}
                    <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isProcessing}
                    >
                        {typeof defaultValues.name === 'undefined' ? 'SAVE' : 'EDIT'}
                    </LoadingButton>
                </Box>
            </Form>
        </FormikProvider>
    );
}
