import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import ForgotPassword from './pages/ForgotPassword';
import Login from './pages/Login';
import Profile from './pages/Profile';
import ParticipantLogin from './pages/ParticipantLogin';
import Register from './pages/Register';
import VideoManager from './pages/VideoManager';
import NotFound from './pages/Page404';
import Meetings from './pages/Meeting';
import Recordings from './pages/Recordings';
import Billing from './pages/Billing';
import ManageParticipants from './pages/ManageParticipants.js';
import Meet from './pages/ParticipantMeet';
import AuthService from './services/auth.service';
import Tokens from './pages/Tokens';
// ----------------------------------------------------------------------

function Logout() {
  AuthService.logout();
  return <Navigate to="/hlogin"></Navigate>
}

export default function Router() {
  // axios.post('http://localhost:4000/users/RegisterUser', { email: 'satyavath2@gmail.com', password: 'abcd123$' }).then((res) => {
  //   alert('done');
  // }).catch((err) => {
  //   alert('error');
  // });
  return useRoutes([
    {
      path: '/meetings',
      element: <DashboardLayout />,
      children: [
        { path: '/meetings', element: <ManageParticipants /> },
      ]
    },
    {
      path: '/recordings',
      element: <DashboardLayout />,
      children: [
        { path: '/recordings', element: <Recordings /> },
      ]
    },
    {
      path: '/billing',
      element: <DashboardLayout />,
      children: [
        { path: '/billing', element: <Billing /> },
      ]
    },
    {
      path: '/contacts',
      element: <DashboardLayout />,
      children: [
        { path: '/contacts', element: <ManageParticipants /> },
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/meet', element: <Meet /> },
        { path: '/forgot-password', element: <ForgotPassword /> },
        { path: 'hlogin', element: <Navigate to="/host" /> },
        { path: 'host', element: <Login /> },
        { path: 'login', element: <Login /> },
        { path: 'logout', element: <Logout /> },
        { path: 'register', element: <Register /> },
        { path: 'profile', element: <Profile /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: '/tokens/:token', element: <Tokens /> },
        { path: '*', element: <Navigate to="/404" /> },
        { path: 'dashboard', element: <VideoManager /> },
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
