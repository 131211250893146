import React from 'react';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import ContactsButton from '../components/ContactsButton';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../components/_dashboard/user';
import BillingPopup from './BillingPopup/BillingPopup';
//
//import MeetingList from '../_mocks_/user';
import AuthService from '../services/auth.service';
import { CircularProgress, Box } from '@mui/material';
import MeetingService from '../services/meeting.service';
import swal from 'sweetalert';
// import MeetingStatusDropdown from './Status/MeetingStatus';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'validityStart', label: 'Invoice Date', alignRight: false },
    { id: 'paidFor', label: 'No Of Users', alignRight: false },
    { id: 'validityEnd', label: 'Due Date', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'amount', label: 'Amount', alignRight: false },
];

function ConvertDateToString(date) {
    return `${date.getDate()}-${date.getMonth()}-${date.getFullYear()} ${formatAMPM(date)}`;
}

function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => ConvertDateToString(new Date(_user.validityStart)).toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function Meetings() {
    const navigate = useNavigate();

    if (!AuthService.getCurrentUser()) {
        window.location.href = '/hlogin';
    }

    const [loaded, setLoaded] = useState(false);
    const [MeetingList, setMeetingList] = useState([]);
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [openPay, setOpenPay] = useState(false);

    if (!loaded) {
        var User = AuthService.getCurrentUser();
        if (!User) {
            window.location.href = '/hlogin';
        }
        AuthService.checkUserData().then((x) => {
            if (!x.data.user || x.data.user?.password !== AuthService.getCurrentUser()._doc.password) {
                AuthService.logout();
                swal({
                    title: 'You will be logged out now',
                    text: 'Your password is changed in an other device',
                    icon: 'error'
                }).then((x) => {
                    window.location.href = "login";
                });
            }
            else {
                const user = { ...AuthService.getCurrentUser(), _doc: x.data.user };
                if (localStorage.getItem('user')) {
                    localStorage.setItem('user', JSON.stringify(user));
                }
                else if (sessionStorage.getItem('user')) {
                    sessionStorage.setItem('user', JSON.stringify(user));
                }

                MeetingService.getUserPayments(User._doc._id).then((data) => {
                    setMeetingList(JSON.parse(data.request.response).data);
                    setLoaded(true);
                }).catch((err) => {
                    swal('Error', err.message, 'error').then((x) => {
                        window.location.reload();
                    });
                });
            }
            // if (x.data.isSame === false) {
            //     AuthService.logout();
            //     swal({
            //         title: 'You will be logged out now',
            //         text: 'Your data is changed in an other device',
            //         icon: 'error'
            //     }).then((x) => {
            //         window.location.href = "login";
            //     });
            // }
            // else {

            // }
        }).catch((err) => {
            AuthService.logout();
            window.location.href = "login";
        });

        return (
            <Box>
                <CircularProgress style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    background: 'white',
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }} />
            </Box>
        );
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = MeetingList.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - MeetingList.length) : 0;

    const filteredUsers = applySortFilter(MeetingList, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredUsers.length === 0;

    return (
        <Page title="Billing | Vmeet">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Billing
                    </Typography>
                    <Button
                        variant="contained"
                        style={{ color: 'white' }}
                        startIcon={<Icon icon={plusFill} />}
                        onClick={() => { setOpenPay(true) }}
                    >
                        Pay Now
                    </Button>
                </Stack>

                <Card>
                    <UserListToolbar
                        numSelected={selected.length}
                        name="Search Invoice Date..."
                        filterName={filterName}
                        onFilterName={handleFilterByName}
                    />

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={MeetingList.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {filteredUsers
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => {
                                            const { paidFor,
                                                validityStart,
                                                _id,
                                                validityEnd,
                                                status,
                                                amount,
                                                paidCurrency } = row;
                                            const isItemSelected = false;

                                            // var dateObj = new Date(startTime);
                                            // var month = dateObj.getMonth() + 1; //months from 1-12
                                            // var day = dateObj.getDate();
                                            // var year = dateObj.getFullYear();

                                            // var newdate = day + "/" + month + "/" + year;

                                            return (
                                                <TableRow
                                                    hover
                                                    key={_id}
                                                    tabIndex={-1}
                                                    selected={isItemSelected}
                                                    style={{ background: (status === 'success' && ((Number(validityStart) <= new Date().getTime()) && (new Date().getTime() <= Number(validityEnd)))) ? '#bfbebb' : '#fff' }}
                                                    aria-checked={isItemSelected}
                                                >
                                                    <TableCell padding="checkbox"></TableCell>
                                                    <TableCell component="th" scope="row" padding="none">
                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                            <Typography variant="subtitle2" noWrap>
                                                                {ConvertDateToString(new Date(validityStart))}
                                                            </Typography>
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell align="left">{paidFor}</TableCell>
                                                    <TableCell align="left">{ConvertDateToString(new Date(validityEnd))}</TableCell>
                                                    <TableCell align="left">
                                                        {status}
                                                    </TableCell>
                                                    <TableCell align="left">{paidCurrency + ' ' + amount}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                                {isUserNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <SearchNotFound searchQuery={filterName} name="Records" />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={MeetingList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    {openPay && (
                        <BillingPopup closeClick={setOpenPay}></BillingPopup>
                    )}
                </Card>
            </Container>
        </Page>
    );
}
