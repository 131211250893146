import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import { alpha } from '@mui/material/styles';
import { Button, Box, Divider, MenuItem, Typography, Avatar, IconButton } from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
//
import AuthService from '../../services/auth.service';
import authService from '../../services/auth.service';
import { CircularProgress } from '@mui/material';
import axios from 'axios';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Create Meeting',
    icon: 'uil:meeting-board',
    linkTo: '/dashboard'
  },
  {
    label: 'Manage Meetings',
    icon: 'bytesize:settings',
    linkTo: '/meetings'
  },
  {
    label: 'Contacts',
    icon: 'fluent:contact-card-group-16-regular',
    linkTo: '/contacts'
  },
  {
    label: 'Recordings',
    icon: 'bi:record-circle',
    linkTo: '/recordings'
  },
  {
    label: 'Profile',
    icon: 'ant-design:user-outlined',
    linkTo: '/profile'
  },
  {
    label: 'Billing',
    icon: 'fluent:receipt-money-24-regular',
    linkTo: '/billing'
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const user = authService.getCurrentUser();
  if (!user) {
    window.location.href = "/login";
    window.location.reload();
  }

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [PHOTO_URL, setUrl] = useState('/static/mock-images/avatars/avatar_default.jpg')

  // if (!loaded) {
  //   axios.get(`/static/profiles/${user._doc._id}`, {
  //     headers: {
  //       'Cache-Control': 'no-cache',
  //       'Pragma': 'no-cache',
  //       'Expires': '0',
  //     }
  //   }).then((x) => {
  //     if (typeof x.data === 'string' && x.data.includes('<html')) { }
  //     else {
  //       //setLoaded(true);
  //       setUrl(`/static/profiles/${user._doc._id}`);
  //     }
  //     setLoaded(true);
  //   }).catch((err) => {
  //     setLoaded(true);
  //   });

  //   return null;
  // }

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // const account = AuthService.getCurrentUser()._doc;

  // if (!account) {
  //   window.location.href = "hlogin";
  //   window.location.reload();
  // }

  return <div></div>;
  // return (
  //   <>
  //     <IconButton
  //       ref={anchorRef}
  //       onClick={handleOpen}
  //       sx={{
  //         padding: 0,
  //         width: 44,
  //         height: 44,
  //         ...(open && {
  //           '&:before': {
  //             zIndex: 1,
  //             content: "''",
  //             width: '100%',
  //             height: '100%',
  //             borderRadius: '50%',
  //             position: 'absolute',
  //             bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
  //           }
  //         })
  //       }}
  //     >
  //       <Avatar src={PHOTO_URL} alt="photoURL" />
  //     </IconButton>

  //     <MenuPopover
  //       open={open}
  //       onClose={handleClose}
  //       anchorEl={anchorRef.current}
  //       sx={{ width: 220 }}
  //     >
  //       <Box sx={{ my: 1.5, px: 2.5 }}>
  //         <Typography variant="subtitle1" noWrap>
  //           {account.firstName + ' ' + account.lastName}
  //         </Typography>
  //         <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
  //           {account.email}
  //         </Typography>
  //       </Box>

  //       <Divider sx={{ my: 1 }} />

  //       {MENU_OPTIONS.map((option) => (
  //         <MenuItem
  //           key={option.label}
  //           to={option.linkTo}
  //           component={RouterLink}
  //           onClick={handleClose}
  //           sx={{ typography: 'body2', color: '#212B36 !important', py: 1, px: 2.5 }}
  //         >
  //           <Icon icon={option.icon} style={{
  //             width: 24,
  //             height: 24,
  //             paddingRight: '5px'
  //           }} />

  //           {option.label}
  //         </MenuItem>
  //       ))}

  //       <Box sx={{ p: 2, pt: 1.5 }}>
  //         <Button fullWidth color="inherit" onClick={() => { window.location.href = "logout"; }} variant="outlined">
  //           Logout
  //         </Button>
  //       </Box>
  //     </MenuPopover>
  //   </>
  // );
}
