import React from 'react';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Box,
    CircularProgress,
    ButtonGroup
} from '@mui/material';
// components
import Scrollbar from '../../Scrollbar';
import SearchNotFound from '../.././SearchNotFound';
import { ContactsToolbar } from '../.././_dashboard/contacts';
import { UserListHead } from '../.././_dashboard/user';
//
//import MeetingList from '../_mocks_/user';
import AuthService from '../../../services/auth.service';
import MeetingService from '../../../services/meeting.service';
import swal from 'sweetalert';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'title', label: 'Name', alignRight: false },
    { id: 'date', label: 'Email', alignRight: false },
    { id: 'time', label: 'Mobile', alignRight: false },
];

function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function ContactChooserTable({ selected, setSelected, setMeetingData }) {
    if (!AuthService.getCurrentUser()) {
        window.location.href = '/hlogin';
    }

    const [loaded, setLoaded] = useState(false);
    const [MeetingList, setMeetingList] = useState([]);
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    // const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);

    if (!loaded) {
        var User = AuthService.getCurrentUser();
        if (!User) {
            window.location.href = '/hlogin';
        }
        AuthService.checkUserData().then((x) => {
            if (!x.data.user || x.data.user?.password !== AuthService.getCurrentUser()._doc.password) {
                AuthService.logout();
                swal({
                    title: 'You will be logged out now',
                    text: 'Your password is changed in an other device',
                    icon: 'error'
                }).then((x) => {
                    window.location.href = "login";
                });
            }
            else {
                const user = { ...AuthService.getCurrentUser(), _doc: x.data.user };
                if (localStorage.getItem('user')) {
                    localStorage.setItem('user', JSON.stringify(user));
                }
                else if (sessionStorage.getItem('user')) {
                    sessionStorage.setItem('user', JSON.stringify(user));
                }

                MeetingService.getUserContacts(User._doc._id).then((data1) => {
                    const data = JSON.parse(data1.request.response).data;
                    setMeetingList(data);
                    setMeetingData(data);
                    setLoaded(true);
                }).catch((err) => {
                    swal('Error', err.message, 'error').then((x) => {
                        window.location.reload();
                    });
                });
            }
            // if (x.data.isSame === false) {
            //     AuthService.logout();
            //     swal({
            //         title: 'You will be logged out now',
            //         text: 'Your data is changed in an other device',
            //         icon: 'error'
            //     }).then((x) => {
            //         window.location.href = "login";
            //     });
            // }
            // else {

            // }
        }).catch((err) => {
            AuthService.logout();
            window.location.href = "login";
        });

        return (
            <Box>
                <CircularProgress style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: 'white',
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }} />
            </Box>
        );
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - MeetingList.length) : 0;

    const filteredUsers = applySortFilter(MeetingList, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredUsers.length === 0;

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            var newSelecteds;
            if (filterName === '')
                newSelecteds = MeetingList.map((n) => n._id);
            else
                newSelecteds = filteredUsers.map((n) => n._id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    return <Card>
        <ContactsToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
        />

        <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                    <UserListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={MeetingList.length}
                        numSelected={selected.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                        checkboxDisplay={true}
                    />
                    <TableBody>
                        {filteredUsers
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                const { _id, name: Name, email: Email, mobile: Mobile } = row;
                                const isItemSelected = selected.indexOf(_id) !== -1;

                                return (
                                    <TableRow
                                        hover
                                        key={index}
                                        tabIndex={-1}
                                        selected={isItemSelected}
                                        aria-checked={isItemSelected}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={isItemSelected}
                                                onChange={(event) => handleClick(event, _id)}
                                            />
                                        </TableCell>
                                        <TableCell component="th" scope="row" padding="none">
                                            <Stack direction="row" alignItems="center" spacing={2}>
                                                <Typography variant="subtitle2" noWrap>
                                                    {Name}
                                                </Typography>
                                            </Stack>
                                        </TableCell>
                                        <TableCell align="left">{Email ?? 'Not Provided'}</TableCell>
                                        <TableCell align="left">{Mobile ?? 'Not Provided'}</TableCell>

                                        {/* <TableCell align="right">
                                        <UserMoreMenu />
                                    </TableCell> */}
                                    </TableRow>
                                );
                            })}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                    {isUserNotFound && (
                        <TableBody>
                            <TableRow>
                                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                    <SearchNotFound name={'participants'} searchQuery={filterName} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
        </Scrollbar>

        <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={MeetingList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </Card>;
}
