import React from 'react';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import ContactsButton from '../components/ContactsButton';
import PopupData from '../components/authentication/video/components/PopupData';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../components/_dashboard/user';
//
//import MeetingList from '../_mocks_/user';
import AuthService from '../services/auth.service';
import { CircularProgress, Box } from '@mui/material';
import MeetingService from '../services/meeting.service';
import swal from 'sweetalert';
import MeetingStatusDropdown from './Status/MeetingStatus';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'title', label: 'Title', alignRight: false },
    { id: 'date', label: 'Date', alignRight: false },
    { id: 'time', label: 'Time', alignRight: false },
    { id: 'contacts', label: 'Contacts', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
];

const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

const nth = function (d) {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
        case 1: return "st";
        case 2: return "nd";
        case 3: return "rd";
        default: return "th";
    }
}

function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.for.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function Recordings() {
    const navigate = useNavigate();

    if (!AuthService.getCurrentUser()) {
        window.location.href = '/hlogin';
    }

    const [loaded, setLoaded] = useState(false);
    const [RecordingsList, setRecordingsList] = useState([]);
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);

    if (!loaded) {
        var User = AuthService.getCurrentUser();
        if (!User) {
            window.location.href = '/hlogin';
        }
        AuthService.checkUserData().then((x) => {
            if (!x.data.user || x.data.user?.password !== AuthService.getCurrentUser()._doc.password) {
                AuthService.logout();
                swal({
                    title: 'You will be logged out now',
                    text: 'Your password is changed in an other device',
                    icon: 'error'
                }).then((x) => {
                    window.location.href = "login";
                });
            }
            else {
                const user = { ...AuthService.getCurrentUser(), _doc: x.data.user };
                if (localStorage.getItem('user')) {
                    localStorage.setItem('user', JSON.stringify(user));
                }
                else if (sessionStorage.getItem('user')) {
                    sessionStorage.setItem('user', JSON.stringify(user));
                }

                MeetingService.getUserRecordings(User._doc._id).then(({ data }) => {
                    var finalData = [];
                    data.map((x) => {
                        var startTime = new Date(x.startTime);
                        finalData.push({
                            realDate: startTime,
                            date: `${monthNames[startTime.getMonth()]} ${Number(startTime.getDate()) + nth(startTime.getDate())} ${startTime.getFullYear()} ${formatAMPM(startTime)}`,
                            for: x.roomName,
                            _id: x._id,
                            videoCompiled: x.videoCompiled
                        });
                    });
                    setRecordingsList(finalData);
                    setLoaded(true);
                }).catch((err) => {
                    swal('Error', err.message, 'error').then((x) => {
                        window.location.reload();
                    });
                });
            }
            // if (x.data.isSame === false) {
            //     AuthService.logout();
            //     swal({
            //         title: 'You will be logged out now',
            //         text: 'Your data is changed in an other device',
            //         icon: 'error'
            //     }).then((x) => {
            //         window.location.href = "login";
            //     });
            // }
            // else {

            // }
        }).catch((err) => {
            AuthService.logout();
            window.location.href = "login";
        });

        return (
            <Box>
                <CircularProgress style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    background: 'white',
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }} />
            </Box>
        );
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = RecordingsList.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - RecordingsList.length) : 0;

    const filteredUsers = applySortFilter(RecordingsList, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredUsers.length === 0;

    return (
        <Page title="Recordings | Vmeet">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Recordings
                    </Typography>
                </Stack>

                <Card>
                    <UserListToolbar
                        numSelected={selected.length}
                        filterName={filterName}
                        onFilterName={handleFilterByName}
                    />

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <PopupData rows={filteredUsers} isUserNotFound={isUserNotFound} filterName={filterName} />
                        </TableContainer>
                    </Scrollbar>
                </Card>
            </Container>
        </Page>
    );
}
