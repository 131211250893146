import { Link as RouterLink, Navigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import AuthService from '../services/auth.service';
import MeetingService from '../services/meeting.service';
import { useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import AuthLayout, { AuthLayoutMob } from '../layouts/AuthLayout';
import { MHidden } from '../components/@material-extend';

const { io } = require("socket.io-client")
// const Chart = window.Chart;

// ----------------------------------------------------------------------
var socket = null;

const RootStyle = styled(Page)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex'
    }
}));

// socket.on('clientCustome', () => {
//     alert('call');
// })

// const SectionStyle = styled(Card)(({ theme }) => ({
//   width: '100%',
//   maxWidth: 464,
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   margin: theme.spacing(2, 0, 2, 2)
// }));

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function ParticipantMeet() {
    var user = AuthService.getCurrentMeetUser();

    const [meetingData, setMeetingData] = useState(JSON.parse(sessionStorage.getItem('meetingData')));

    const [message, setMessage] = useState('Joining Meeting...');
    const [url, setUrl] = useState('');
    const [loaded, setLoaded] = useState(Boolean(meetingData?.url));
    const [iframeLoaded, setIframeLoaded] = useState(false);

    if (!user && !meetingData?.url) {
        return <Navigate to="/login" />
    }

    if (socket === null) {
        socket = io('https://vmeet.visualbench.com:8443');
    }

    if (meetingData?.url) {
        socket.emit('userJoinMeeting', { name: meetingData.name, userId: meetingData.userId, meetingId: meetingData.meetingId, ishost: true });

        socket.on('canJoinMeeting', function (data) {
            if (data.canJoin) {
                // document.getElementById('root').removeChild(document.getElementById('root').children[0]);
                setUrl(meetingData?.url);

            }
            else {
                // sessionStorage.removeItem('meetingData');
                setMessage(data.message);
            }
        });
    }
    else if (!loaded) {
        MeetingService.generateAccessToken(user, false).then((res) => {
            socket.emit('userJoinMeeting', { name: user.name, userId: user.userId, meetingId: user.meetingId });

            socket.on('canJoinMeeting', function (data) {
                if (data.canJoin) {
                    // document.getElementById('root').removeChild(document.getElementById('root').children[0]);
                    setUrl(`https://meet.visualbench.com/${user.meetingId}?jwt=${res.data}`);
                }
                else {
                    setMessage(data.message);
                }
            });

            setLoaded(true);
        }).catch((err) => {
            setMessage(err.response.data.error);
            console.log(err);
            if (err.response.data.refresh)
                setInterval(() => { window.location.reload() }, 60000);
            else
                setLoaded(true);
            // var barOptions_stacked1 = {
            //     tooltips: {
            //         enabled: true
            //     },
            //     hover: {
            //         animationDuration: 0
            //     },
            //     scales: {
            //         xAxes: [{
            //             ticks: {
            //                 beginAtZero: true,
            //                 fontFamily: "'Open Sans Bold', sans-serif",
            //                 fontSize: 11
            //             },
            //             scaleLabel: {
            //                 display: false
            //             },
            //             gridLines: {
            //             },
            //             stacked: true
            //         }],
            //         yAxes: [{
            //             barPercentage: 1.0,
            //             categoryPercentage: 1.0,
            //             barThickness: 20,
            //             gridLines: {
            //                 display: false,
            //                 color: "#fff",
            //                 zeroLineColor: "#fff",
            //                 zeroLineWidth: 0
            //             },
            //             ticks: {
            //                 fontFamily: "'Open Sans Bold', sans-serif",
            //                 fontSize: 11
            //             },
            //             stacked: true
            //         }]
            //     },
            //     legend: {
            //         display: true
            //     },
            //     pointLabelFontFamily: "Quadon Extra Bold",
            //     scaleFontFamily: "Quadon Extra Bold"
            // };
            // var ctx1 = document.getElementById("Chart1");
            // var myChart1 = new Chart(ctx1, {
            //     type: 'horizontalBar',
            //     data: {
            //         labels: ["BU 5", "BU 4", "BU 3", "BU 2", "BU 1"],
            //         datasets: [{
            //             data: [727, 589, 537, 543, 574],
            //             backgroundColor: "rgba(63,103,126,1)",
            //             hoverBackgroundColor: "rgba(50,90,100,1)",
            //             label: "newly request"
            //         }, {
            //             data: [238, 553, 746, 884, 903],
            //             backgroundColor: "rgba(163,103,126,1)",
            //             hoverBackgroundColor: "rgba(140,85,100,1)",
            //             label: "in progress"
            //         }, {
            //             data: [1238, 553, 746, 884, 903],
            //             backgroundColor: "rgba(63,203,226,1)",
            //             hoverBackgroundColor: "rgba(46,185,235,1)",
            //             label: "active"
            //         }, {
            //             data: [1338, 553, 746, 884, 903],
            //             backgroundColor: "rgba(255,169,188,1)",
            //             hoverBackgroundColor: "rgba(255,99,132,1)",
            //             label: "in approval"
            //         }, {
            //             data: [1438, 553, 746, 884, 903],
            //             backgroundColor: "rgba(136,202,247,1)",
            //             hoverBackgroundColor: "rgba(54,162,235,1)",
            //             label: "recycle"
            //         }, {
            //             data: [1538, 553, 746, 884, 903],
            //             backgroundColor: "rgba(196,232,116,1)",
            //             hoverBackgroundColor: "rgba(152,177,98,1)",
            //             label: "reject"
            //         }]
            //     },
            //     options: barOptions_stacked1
            // });
        });
    }

    return (
        <RootStyle style={{ width: '100%', height: '100%' }} title="Meet | Vmeet">
            {/* <canvas id="Chart1"></canvas> */}
            {url != '' && !iframeLoaded && (<Box style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                <CircularProgress style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    background: 'white',
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }} />
            </Box>)}

            {!iframeLoaded && <>         <MHidden width="smDown">
                <AuthLayout>
                </AuthLayout>
            </MHidden>

                <MHidden width="smUp">
                    <AuthLayoutMob>
                    </AuthLayoutMob>
                </MHidden></>}
            {/* <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Join Meeting
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden> */}
            {url != '' ? (<iframe style={{ display: iframeLoaded ? 'initial' : 'none', zIndex: 1 }} onLoad={() => { setIframeLoaded(true) }} allowFullScreen={true} allow="camera; microphone; display-capture; autoplay; clipboard-write" src={url} width={'100%'} height={'100%'} />)
                : (<Container>
                    <ContentStyle>
                        <Stack sx={{ mb: 5 }}>
                            <Typography variant="h4" gutterBottom>
                                {message}
                            </Typography>
                        </Stack>
                        {/* <AuthSocial /> */}

                        {/* <ParticipantLoginForm /> */}
                    </ContentStyle>
                </Container>)}
        </RootStyle>
    );
}
