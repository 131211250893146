import React from "react";
import DragM from "dragm";
import { Modal } from "antd";
import '../../../../../node_modules/antd/dist/antd.css'
import AuthService from "../../../../services/auth.service";
import ImportParticipantsForm from '../ContactChooserTable';
import { Button } from "@mui/material";
// const options = [
//   { value: 'chocolate', label: 'Chocolate' },
//   { value: 'strawberry', label: 'Strawberry' },
//   { value: 'vanilla', label: 'Vanilla' }
// ]

class BuildTitle extends React.Component {
    updateTransform = transformStr => {
        this.modalDom.style.transform = transformStr;
    };
    componentDidMount() {
        this.modalDom = document.getElementsByClassName(
            "ant-modal-wrap" //modal的class是ant-modal-wrap
        )[0];
    }
    render() {
        const { title } = this.props;

        return (
            <DragM updateTransform={this.updateTransform}>
                <div>{title}</div>
            </DragM>
        );
    }
}
class App extends React.Component {
    constructor(props) {
        super(props);

        const user = AuthService.getCurrentUser()?._doc;
        if (!user) {
            window.location.href = "/login";
            window.location.reload();
        }

        this.state = { selected: this.props.data, MeetingData: [], error: '' }
        // this.state = { index: null, data: null };

        // var index = 0;
        // var options = [];

        // this.props.contacts.map((x) => {
        //     options.push({ value: '' + index, label: x.Name, data: x });
        //     index++;
        // });

        // this.options = options;
    }
    handleOk = e => {
        if (this.state.selected.length === 0) {
            return this.setState({ error: 'No Participants are selected' })
        }
        var selected = [...this.state.selected];
        var selectedContact = [];
        var addedParticipants = [];
        var idsArray = this.props.idsArray;
        selected.map((x) => {
            var meetingdata = this.state.MeetingData.find((y) => { return y._id === x });
            var contac = {};
            contac.Name = meetingdata.name;
            contac.Mobile = meetingdata.mobile;
            contac.Email = meetingdata.email;
            contac.id = meetingdata._id;
            if (idsArray) {
                if (!idsArray.includes(contac.id)) {
                    addedParticipants.push(contac.id);
                }
            }
            selectedContact.push(contac);
            return contac;
        });
        this.props.onClose(selectedContact, selected, addedParticipants);
    };
    handleCancel = e => {
        this.props.onClose();
    };
    render() {
        const title = <BuildTitle title={this.props.title} />;
        return (
            <div>
                <Modal
                    title={title}
                    visible={true}
                    okText={'Choose'}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <ImportParticipantsForm selected={this.state.selected} setMeetingData={(value) => { this.setState({ MeetingData: value }) }} setSelected={(value) => { this.setState({ selected: value }) }} />
                    {this.state.error != '' && <div style={{ color: "red" }}>
                        <span>{this.state.error}</span>
                    </div>}
                </Modal>
            </div>
        );
    }
}

export default App;