import React from "react";
import DragM from "dragm";
import { Modal } from "antd";
import '../../../../../node_modules/antd/dist/antd.css'
import AuthService from "../../../../services/auth.service";
import ImportParticipantsForm from './ImportParticipantsForm';
// const options = [
//   { value: 'chocolate', label: 'Chocolate' },
//   { value: 'strawberry', label: 'Strawberry' },
//   { value: 'vanilla', label: 'Vanilla' }
// ]

class BuildTitle extends React.Component {
    updateTransform = transformStr => {
        this.modalDom.style.transform = transformStr;
    };
    componentDidMount() {
        this.modalDom = document.getElementsByClassName(
            "ant-modal-wrap" //modal的class是ant-modal-wrap
        )[0];
    }
    render() {
        const { title } = this.props;

        return (
            <DragM updateTransform={this.updateTransform}>
                <div>{title}</div>
            </DragM>
        );
    }
}
class App extends React.Component {
    constructor(props) {
        super(props);

        const user = AuthService.getCurrentUser()?._doc;
        if (!user) {
            window.location.href = "/login";
            window.location.reload();
        }

        // this.state = { name: '', email: '' }
        // this.state = { index: null, data: null };

        // var index = 0;
        // var options = [];

        // this.props.contacts.map((x) => {
        //     options.push({ value: '' + index, label: x.Name, data: x });
        //     index++;
        // });

        // this.options = options;
    }
    handleOk = e => {
        this.props.onClose(false);
    };
    handleCancel = e => {
        this.props.onClose(false);
    };
    render() {
        const title = <BuildTitle title={this.props.title} />;
        return (
            <div>
                <Modal
                    title={title}
                    visible={true}
                    okButtonProps={{ style: { display: 'none' } }}
                    // okText={'SAVE'}
                    // onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <ImportParticipantsForm data={this.props.data} />
                </Modal>
            </div>
        );
    }
}

export default App;